import React, {Component} from 'react';
import './Makepayment.css';
class Makepayment extends React.Component {
  
  render() {
    return <div className="container">
                <div className=" row text-center p-3 backgrnd mt-3">
             <img src="/assets/images/logo3.png" alt="" title="" className="the-logo"/>
                    <span className="float-right next-step">
                        <h5 className="head-clr mb-0"> Woolly Farms </h5>
                        <p className="head-clr">Next steps to a healthier life </p>
                        <p className="head-clr pay-it mb-0">₹&nbsp;500</p>
                    </span>
                </div>
                <div className="row box-grid mt-2">
                <a className="" href=""> <img src="/assets/images/icon1.png" alt="" title="" className="card-icn mb-2"/></a>
                <span className="float-right mt-4 ml-1 no-here">+918606073737<span className="float-right id-here ml-2">sibin@123.com</span></span>
                </div>
                <div className=" row">
                    <p className="more ml-4 mb-0 mt-4">CARDS,UPI & MORE</p>
                    <div class="grid-container mt-2 mb-3">
                        <div class="item1">
                            <a className="" href=""> <img src="/assets/images/icon2.png" alt="" title="" className="card-icn"/></a>
                            <span className="float-right how-to-pay">
                                <h5 className="mt-2">Card</h5>
                                <p className="need-pay">Visa,MasterCard,RuPay,and Maestro</p>
                            </span>
                        </div>
                        <div class="item1">
                            <a className="" href=""> <img src="/assets/images/icon3.png" alt="" title="" className="card-icn"/></a>
                            <span className="float-right how-to-pay">
                                <h5 className="mt-2">UPI</h5>
                                <p className="need-pay">Google Pay,PhonePe & more</p>
                            </span>
                        </div>
                        <div class="item1">
                            <a className="" href=""> <img src="/assets/images/icon4.png" alt="" title="" className="card-icn"/></a>
                            <span className="float-right how-to-pay">
                                <h5 className="mt-2">Netbanking</h5>
                                <p className="need-pay">All Indian banks</p>
                            </span>
                        </div>
                        <div class="item1">
                            <a className="" href=""> <img src="/assets/images/icon5.png" alt="" title="" className="card-icn"/></a>
                            <span className="float-right how-to-pay">
                                <h5 className="mt-2">Wallet</h5>
                                <p className="need-pay">Mobikwik & More</p>
                            </span>
                        </div>
                        <div class="item1">
                            <a className="" href=""> <img src="/assets/images/icon6.png" alt="" title="" className="card-icn"/></a>
                            <span className="float-right how-to-pay">
                                <h5 className="mt-2">Pay Later</h5>
                                <p className="need-pay">ePayLater & More</p>
                            </span>
                        </div>
    </div>


        </div>

  </div>



    }
}

export default Makepayment;
