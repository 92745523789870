import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './HomeSlider.css';

class HomeSlider extends React.Component {
    constructor(){
        super();
        this.state = {
            slider : []
        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.web_main_slider', {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({slider:response.data['message']})
            
            })
        
    }
    render() {

        var settings = {
            slidesToShow: 1,
            fade: true,
            speed: 500,
            slidesToScroll: 1,
            fade: true,
            loop: true,
            dots: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
          
        }
      return  <div className="container">
    <div className="home-slider ">
          <div className="hero-slider-active-2 nav-style-1 nav-style-1-green">
          <Slider {...settings}>
            {this.state.slider.map((itm,p)=>{
            return(
                    
                    <div key={p} className="slider-item" >
                        
                        <img src={process.env.REACT_APP_API_URL + itm.image} rel="preload" width="100%"/>
                        
                    </div>)
            })}
            </Slider>
      </div>
    </div>
  </div>
}}

export default HomeSlider;