import React, {Component} from 'react';
import axios from 'axios';
import AuthService from '../Services/auth.service';
import './Myprofileaddress.css';
import './Complaintitem.css';
class Myprofileaddress extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      full_name: undefined,
      email:undefined
    };
}
async componentDidMount(){
  this.setState({full_name:this.props.Fname})
  this.setState({email:this.props.Email})

}

  handleSubmit = async (e) => {
    e.preventDefault();
      if(!this.Email){
        this.Email=this.state.email
      }
      const addressData= {
        full_name:this.FullName,
        email:this.Email
      }
      await AuthService.updateProfile(addressData)
            .then(response=>{ 
                this.props.handleCancel('false')
                window.location.href = "/my-account.html"; 
            })
            .catch(err=>{console.log(err)})


  
}
  render() {
   

    return <div>

<div className="row  ">


  <div className="    center-space">
  <div className="text-center">
          <a className="" href=""> <img src="/assets/images/logo.svg" alt="" title="" className="img-fluid" /> </a>
      </div>
  <form onSubmit={this.handleSubmit}>
      <div className="ml-3">
        
      <a className="" href=""> <img src="/assets/images/img_avatar.png" alt="" title="" className="avatar-img mt-4 ml-4" /> </a>

                    <div class="row-form  mt-3 center-space ml-3 ">
                        <h6  class="green-label "> Full Name</h6>
                        <input class="form-controls " defaultValue ={this.state.full_name} onChange={e => this.FullName = e.target.value} type="text"    />
                    </div> 
                        
                    <div class="row-form   center-space  mb-2 ml-3 ">
                        <h6  class="green-label ">E-mail Address</h6>
                        <input class="form-controls " value ={this.state.email} onChange={e => this.Email = e.target.value}  type="text" placeholder="" id="email" />
                    </div>
               </div>     
                    <div className=" mb-2 mt-4  ">  
                      <button type="button" class="  btnn btn-hover btn-outline-success tag-btn mob-btn-mb-15  " onClick={e => this.props.handleCancel('false')}>Back</button>
                      <button type="button" type="submit" class=" add-to-cart2 btnn tag-btn  mt-0 mb-2 "  >Submit</button>
                    </div>

                    </form>
      </div>
      


   




</div>







    </div>
    }
}

export default Myprofileaddress;
