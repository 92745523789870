import React, {Component} from 'react';
import { render } from "react-dom";
import axios from 'axios';
import './Harvestoftheday.css';
import './Cartitem.css';
import {Helmet} from "react-helmet";
import AuthService from '../Services/auth.service';
import CartService from '../Services/cart.service';
import {  InputGroup, Input, Button, InputGroupAddon } from "reactstrap";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ReactLoading from 'react-loading';


class Cartitem extends React.Component {
  constructor(props){
    super(props);
        this.state = {
            selectedQty : 1,
            showQty: undefined,
            disabled:'',
            rate:'',
            selectedItem: undefined,
            selectedRate: undefined,
            showLoading:''

        }
    }
    async componentDidMount(){
        this.setState({ selectedItem: this.props.itm.item_code })
        this.setState({ selectedQty: this.props.itm.qty })
        this.setState({ rate: this.props.itm.rate })
    }
    componentDidUpdate(prevState){
     
    }
    
    addQty = async e => {
        e.preventDefault();
        this.setState({showLoading:<ReactLoading type="bars" color="#000" height={50} width={35} />})
        await CartService.addQty(this.state.selectedItem, this.state.selectedQty)
            .then(res=>{ 
                this.props.handleUpdate(); 
                this.setState({showLoading:''}) 
            })
        this.setState({ selectedQty: this.state.selectedQty + 1 })        
        
    }
    subQty= async e => {
        e.preventDefault();
        this.setState({showLoading:<ReactLoading type="bars" color="#000" height={50} width={35} />})
        await CartService.subQty(this.state.selectedItem, this.state.selectedQty)
            .then(res=>{ 
                
                this.props.handleUpdate();
                 this.setState({showLoading:''}) 
             })
            this.setState({ selectedQty: (this.state.selectedQty > 0 )?this.state.selectedQty - 1 : this.state.selectedQty })
    


    }
    async removeItem (e, itemcode){
        const delay = ms => new Promise(res => setTimeout(res, ms));
        e.preventDefault();
        this.setState({showLoading:<ReactLoading type="bars" color="#000" height={50} width={35} />})
        await CartService.removeItem(itemcode)
        .then(res=>{ 
            this.props.handleUpdate();
             
            this.setState({showLoading:''}) 
         })       
        //CartService.update_cart_local()
      }
    render() {
          //console.log(this.props.product)
          
          
    return <div className="row container pb-3 cart-product" >    
                    <div className="col-3 col-md-3 text-center">
                        <img src={( this.props.itm.image )? process.env.REACT_APP_API_URL +this.props.itm.image:'/assets/images/missing.jpg'} style={{maxHeight: 150}}    className="img-fluid" alt=""/>
                    </div>
                    <div className="col-6 col-md-6 bbottom ">
                        <h3 className="media-title font-weight-semibold mb-0"> <a href="#" data-abc="true">{this.props.itm.item_name}</a> </h3>
                        {this.state.showLoading}
                        <ul className="list-inline list-inline-dotted mb-3 mb-lg-2">
                            <li className="list-inline-item"><a href="#" className="text-muted" data-abc="true">{this.props.itm.description} / Rs {this.props.itm.rate}</a></li>
                        </ul>
                        <div className="stepper-widget mb-3 product-price">
                        <InputGroup >
                                <InputGroupAddon addonType="prepend" onClick={this.subQty}>
                                    <Button type="button" color="" >
                                        <RemoveOutlinedIcon />
                                    </Button>
                                </InputGroupAddon>
                                                        
                                <Input type="text" className="" value={this.state.selectedQty} placeholder="number" readOnly />
                                                        
                                <InputGroupAddon addonType="append" onClick={this.addQty}>
                                        <Button type="button"  color="" >
                                        <AddOutlinedIcon/>
                                        </Button>
                                                        </InputGroupAddon>
                                                    
                            </InputGroup>
                        </div>
                    </div>   
                    <div className="col-3 col-md-3 text-right bbottom">
                        <h3 className="mb-0 font-weight-semibold new-head">Rs {this.state.rate * this.state.selectedQty}</h3>
                        <br/>
                        <br/>
                        <span><p><a href="#" data-itemcode={this.props.itm.item_code} onClick={(e) => { this.removeItem(e, this.props.itm.item_code);}}    ><DeleteOutlinedIcon className="delete"/></a></p></span>
                    </div>
                    
                </div>
         }
        }
export default Cartitem;