import React, {Component} from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
class Footer2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu : []
            

        }
    }; 
componentDidMount(){
    axios.get(encodeURI(process.env.REACT_APP_API_URL + 'api/resource/Homepage Section/Payment Footer'), {
        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
    'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({menu:response.data['data']})
    //console.log(response.data['data'])
    })
}
    render() {
        return <ReactHtml html={this.state.menu['section_html']}/>
        }}

        export default Footer2;