import React, {Component} from 'react';
import axios from 'axios';
import './Payment.css';
import Breadcrumb from '../Components/Breadcrumb';

import AuthService from '../Services/auth.service';
import CartService from '../Services/cart.service';
class Success extends React.Component {
    constructor(props){
       
        super(props);
        this.state = {
            cartItems: [],
            cartTotal: [],
            cartGrandTotal: [],
            address:[],
            wallet:0,
            checkoutLoader:'Place Order'
            }
        }


    async componentDidMount(){
       
       
    
    }

    

    render() {
        if (!this.state.cartGrandTotal > 0){
            window.location.href = "/"
        }

        return <div className="emptycart mt-5 mb-5">
                <h2>Your Order Placed Successfully!</h2>
                <p> </p>
                <img src="/assets/images/success.jpg" />

                <div class="d-flex justify-content-center">
                        <button type="button" onClick={event =>  window.location.href='/my-orders.html'}  className=" center-block add-to-cart2 btnn-wide " style={{ maxWidth: 250 }} >View Order</button>
                </div>

            </div> 
          
        
        
         
    }       
}

export default Success;