import React, {Component} from 'react';
import './Refferr.css';
class Refferr extends React.Component {
  
  render() {
    return <div>

  <div className="container ">

      <div className="text-center p-5">
           
      <a className="" href=""> <img src="/assets/images/logo.svg" alt="" title="" className="img-fluid pop-img" /> </a><br/>
      <a className="" href=""> <img src="/assets/images/welcome.png" alt="" title="" className="img-fluid mb-3 mt-1 pop-img1"/> </a><br/>
       <p className=" center-line">If you have a unique referral code.please<br/>enter it here</p>
      <p className="green-label enter "> Enter Unique Code</p>
      <input class="form-controls frn--ctr ml-4 line" type="text" placeholder=""  id="example-email-input" onChange={e => this.mobileNo = e.target.value}/>
      <u className="underline"><p className="enter green-label skip">Skip and start <br/>shopping</p></u>
      <button type="button" class=" add-to-cart2 btnn  reffer-btn">Next</button>


      </div>

  </div>


    </div>
    }
}

export default Refferr;