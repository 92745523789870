import React, {Component} from 'react';
import axios from 'axios';
import './Address1.css';
import './Address.css';
import './Complaintitem.css';
import Map from '../Components/Map';
import AuthService from '../Services/auth.service';
import MyLocationIcon from '@material-ui/icons/MyLocation';
class Address extends React.Component {
    constructor(props){
        super(props);
        this.state = {
           value:false,
           homeChk:0,
          workChk:0,
          otherChk:0,
          }
          this.MyCountry = 'India'
          this.Tan='0.00'
          this.Long='0.00'
          
          this.label=''
          this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleSubmit = e => {
  
        const addressData= {
        address_line1:this.FirstAddress,
        address_line2:this.SecondAddress,
        pincode:this.MyPincode,
        landmark:this.MyLandmark,
        city:this.MyCity,
        latitude:this.Tan,
        longitude:this.Long,
        country:this.MyCountry,
        address_label:this.label
        }
        
        const USR_TOKEN = localStorage.getItem("userToken")
          if(USR_TOKEN){
             //alert(USR_TOKEN)
              const user =  AuthService.getCurrentUser();
              this.setState({profile:user})
              this.setState({value:true})
                axios.post(process.env.REACT_APP_API_URL+'api/method/wolly.api.add_new_address',addressData,{
                headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
                
                })      
                .then(  
                    res =>{
                        this.setState({value:true})
                        window.location.href = "/my-account.html"; 
                        } 
                )
                .catch(
                    err =>{
                        alert("failed")
                    }
                )
          }
          else{
            alert("Not Logged in")
        }

        /* if(this.state.value){
            alert("success")
            window.location.href = "/my-account.html";

        } 
        else{
            alert("failed")
        }       */
            
        
      }

      async handleInputChange(e){
         
        const target = e.target;
        const name = target.name;
        const value = target.value;
        this.label = target.name
        if(target.name=='Home'){
            this.setState({homeChk:1})
            this.setState({workChk:0})
            this.setState({otherChk:0})
        }
        if(target.name=='Work'){
            this.setState({homeChk:0})
            this.setState({workChk:1})
            this.setState({otherChk:0})
        }
        if(target.name=='Other'){
            this.setState({homeChk:0})
            this.setState({workChk:0})
            this.setState({otherChk:1})
        }
      }
  render() {
     
      
    
    return <div className="container ">
        <h3 className=" ml-2 address-titlt">Add New Address</h3>
       {/* <div className="mb-0">
                } <Map
                                google={this.props.google}
                                center={{lat: 18.5204, lng: 73.8567}}
                                height='180px'
                                zoom={15}
                    />
        </div>
        <div class="row mt-2">
        <div className="my-address" >
        <h5 className="locate mb-1 ">Your Location</h5> 
        <p className="new-product address pl-1"></p>
        </div>
        
        <button type="button" class="  ml-5  loc-btn">Locate<MyLocationIcon className="ml-2"/></button>
  </div>*/}
        {/* <form onSubmit={this.handleSubmit}> */}
        <div class="row-form mb-4">
            <input class="form-controls left-space " type="text" placeholder="House / Flat No" id="example-email-input" onChange={e => this.FirstAddress = e.target.value} />
        </div>
        <div class="row-form mb-4">
            <input class="form-controls left-space" type="text" placeholder="Address / Block" id="example-email-input" onChange={e => this.SecondAddress = e.target.value}/>
        </div>
        <div class="row-form   center-space  ">
            <h7  class="green-label pl-1 "> Pincode</h7>
            <input class="form-controls " type="text" placeholder="" id="example-email-input" onChange={e => this.MyPincode = e.target.value}/>
        </div> 
        <div class="row-form   center-space  ">
            <h7  class="green-label pl-1 ">Landmark</h7>
            <input class="form-controls " type="text" placeholder="" id="example-email-input" onChange={e => this.MyLandmark = e.target.value}/>
        </div>
        <div class="row-form   center-space  ">
            <h7  class="green-label pl-1 ">City</h7>
            <input class="form-controls " type="text" placeholder="" id="example-email-input" onChange={e => this.MyCity = e.target.value}/>
        </div>
        <div class="row-form   center-space  d-none ">
        <h7  class="green-label pl-1 ">Country</h7>
            <input class="form-controls " type="text" placeholder="" defaultValue="India" id="example-email-input" onChange={e => this.MyCountry = e.target.value}/>
        </div>
        <div class="row-form   center-space d-none ">
            <h7  class="green-label pl-1 ">Latitude</h7>
            <input class="form-controls " type="text" placeholder="" id="example-email-input" onChange={e => this.Tan = e.target.value}/>
        </div>
        <div class="row-form   center-space  d-none ">
            <h7  class="green-label pl-1 ">Longitude</h7>
            <input class="form-controls " type="text" placeholder="" id="example-email-input" onChange={e => this.Long = e.target.value}/>
        </div>
        <ul class="category-list inline"> 
            <li><div className="custom-control custom-checkbox ml-1">
                <input onChange={this.handleInputChange} type="checkbox" name="Home" checked={this.state.homeChk} class="custom-control-input mr-3" id="defaultu" />
                    <label class="custom-control-label product-details mr-3 " for="defaultu">Home</label>
            </div></li>
            <li><div className="custom-control custom-checkbox">
                <input onChange={this.handleInputChange} type="checkbox" name="Work" checked={this.state.workChk} class="custom-control-input mr-3" id="defaultUnchecked-2"/>
                <label class="custom-control-label product-details mr-3" for="defaultUnchecked-2">Work</label>
            </div></li>
            <li><div className="custom-control custom-checkbox">
                <input onChange={this.handleInputChange} type="checkbox" name="Other" checked={this.state.otherChk} class="custom-control-input mr-3" id="defaultUnchecked-3"/>
                <label class="custom-control-label product-details mr-3" for="defaultUnchecked-3">Other</label>
            </div></li>
        </ul>
        <div className="inline  mobi-sub2-btn mb-2 mt-3 com-itm-btn2 mob-nex-btn mob-nex-btn2 ">
        <button type="button" class="  btnn btn-hover btn-outline-success tag-btn  mob-can-btn mob-add-bk-btn ml-3" onClick={() => this.props.handleCancel('false')}>Back</button>

        <button type="button"  type="submit" class=" add-to-cart2 btnn tag-btn mb-2  mob-add-sub-btn " onClick={() => this.handleSubmit()}>Submit</button>
        
      </div>
     {/*  </form> */}
    </div>

        }
    }
    
    export default Address;