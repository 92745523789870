import React, {Component} from 'react';
import axios from 'axios';
import './Signup2.css';

import AuthService from '../Services/auth.service';
class OTPCheck extends React.Component {

constructor(){
    super();
    this.state = {
        cats : [],
    }
  // this.signUp = this.signUp.bind(this);
}
 

  render() {
    return <div>

                <div className="row  ">


                  <div className="   pt-3 pb-5 center-space pop-up">
                    <div className="text-center">
                    <a className="" href=""> <img src="/assets/images/logo.svg" alt="" title="" className="img-fluid mb-5" /> </a><br/>
                    <a className="" href=""> <img src="/assets/images/sign1.png" alt="" title="" className="img-fluid mb-2" /> </a><br/>
                      </div>
                            <div class="row-form   center-space pl-3 ">
                                        <h6 class="green-label ">Enter OTP</h6>
                                        <input class="form-controls frn--ctr"  placeholder=""id="partitioned" type="text" maxLength="5" onChange={e => this.otp = e.target.value}/>
                                        <p class={this.props.msgClass}>{this.props.msg}</p>

                                        <a href="#" onClick={() => this.props.handleNoChange()}>Change Mobile Number</a> <br></br>
                                        <a href="#" onClick={() => this.props.resendOTP()}>Resend OTP</a>
                                        </div> 
                                        <div className="text-center top-up mt-3">
                                              <button type="button" class=" add-to-cart2 btnn " onClick={() => this.props.updateState(this.otp)}>Next</button><br/>
                                              <button type="button" class="  btnn  btn-outline-green" onClick={() => this.props.handleCancel('false')}>Cancel</button></div>
                                        </div>
                </div>
    </div>
    }
}

export default OTPCheck;
