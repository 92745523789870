import React, {Component} from 'react';
import axios from 'axios';
import {BrowserRouter as Router, Redirect } from 'react-router-dom';

import {  InputGroup, Input, Button, InputGroupAddon, Modal,ModalHeader, ModalBody } from "reactstrap";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import ReactHtml from 'raw-html-react';
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import './Cart.css';
import Coupondpdn from '../Components/Coupondpdn';
import AuthService from '../Services/auth.service';
import CartService from '../Services/cart.service';
import Cartitem from '../Components/Cartitem';
import Optin from '../Components/Optin';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Address from '../Pop-up/Address';
import Emptycart from '../Components/Emptycart';
class Cart extends React.Component {

  constructor(props){
       
    super(props);
    this.state = {
        cartItems: [],
        cartTotal: [],
        cartGrandTotal: [],
        optinItems: [],
        address:[],
        deliveryslot:[],
        loggedIn:false,
        addModal:false,
        shipAddress:[],
        shipAddressName:undefined,
        addressLoader:'',
        slotLoader:'',
        checkoutLoader:'Checkout',
        orderDetails:[],
        razorOrder:[],
        paymentPopup:false,
        addressStatus:false,
        orderStatus:false,
        cartEmpty:true,
        coupon_code:undefined,
        discount_amount:0.00,
        delModal:false,
        tax:0.00,
        deliverycharge:0.00,
        slotModal:false,
        selectedSlot:''

    }
    this.getCart = this.getCart.bind(this)
    this.getOptin = this.getOptin.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleOptinUpdate = this.handleOptinUpdate.bind(this)

    this.handleChange = this.handleChange.bind(this);

  }
async componentDidMount(){
    this.getCart()
    this.getOptin()
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

}
handleChange(evt){
  console.log(evt.target.value)
  this.setState({
    amount:evt.target.value
  })
}


async getCart(){
  const USR_TOKEN = localStorage.getItem("userToken")
  if(USR_TOKEN){
    this.setState({loggedIn:true})
    let cart = AuthService.getserverCartitems()
    if(cart){
      this.setState({cartEmpty:false})
    }
    await CartService.getCart()
    .then(response=>{ 
        //console.log("getCart")
        if(response['doc']['items'].length<1){
          this.setState({cartEmpty:true})
        }else { this.setState({cartEmpty:false}) }

        this.setState({cartItems:response['doc']['items']})
        this.setState({cartTotal:response['doc']['total']})
        this.setState({tax:response['doc']['total_taxes_and_charges']})
        this.setState({cartGrandTotal:response['doc']['grand_total']})
        this.setState({deliveryslot:response['delivery_slots']})
        this.setState({selectedSlot:response['delivery_slots'][0]})
        if(response['doc']['shipping_address']){
            this.setState({address:response['doc']['shipping_address']})
            this.setState({addressStatus:true})
        }
        
        this.setState({shipAddress:response['shipping_addresses']})
        this.setState({shipAddressName:response['doc']['shipping_address_name']})
        this.setState({coupon_code:response['doc']['coupon_code']})
        this.setState({discount_amount:response['doc']['base_discount_amount']})
    })
    .catch(err=>{ console.log(err)})
    

    

  }
  else{

      let cart = AuthService.getCartitems()
     
      if(cart){
          this.setState({cartQty:cart.length})
          let total = parseFloat(0.00);
          if(cart.length > 0){
              this.setState({cartEmpty:false})
              for (let itm in cart) {
                  total += parseFloat(cart[itm]['rate']*cart[itm]['qty']);
              } 
          }
          else { this.setState({cartEmpty:true}) }
          this.setState({cartTotal:total})
          this.setState({cartItems:cart})
          this.setState({cartGrandTotal:parseFloat(total) })
      }

  }
  CartService.update_cart()

}


getOptin(){
 
      axios.get(process.env.REACT_APP_API_URL + encodeURI('api/method/wolly.api.optin_items'),{
          headers : {'content-type': 'application/json'}
      })
      .then(response=>{
          this.setState({optinItems:response.data['message']})
      })
}

        
handleUpdate(e) {
    this.setState({cartItems:[]})
    this.getCart()
   // CartService.update_cart()
}
handleOptinUpdate(e) {
  this.setState({cartItems:[]})
  this.getCart()
    //this.getCart()
   // CartService.update_cart()
}
addressPop() {
  if(this.state.cartItems.length > 0){
    this.setState({ addModal: true });
  }
}

slotPop() {
  if(this.state.cartItems.length > 0){
    this.setState({ slotModal: true });
  }
}
async remvoveCoupon(e){
  const USR_TOKEN = localStorage.getItem("userToken")
  if(USR_TOKEN){
    let applied_code = { applied_code: ''}
      axios.post(process.env.REACT_APP_API_URL + encodeURI('api/method/wolly.api.remove_coupon'), applied_code,{
          headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
      })
      .then(response=>{
        this.setState({cartItems:[]})
        this.getCart()
      })
  }
}

async selectAddress(e, add_name){
  if(this.state.cartItems.length > 0){
      this.setState({ addressLoader: <ReactLoading type="bars" color="#000" height={50} width={35} /> });
      this.setState({ shipAddressName: add_name });
      await CartService.setAddress(add_name)
      .then(res=>{  
          this.setState({ addressLoader: '' });
          this.setState({addressStatus:true})
          this.getCart()
          this.setState({ addModal: false });
      })
    }
}
async selectSlot(e, slot){
  if(this.state.cartItems.length > 0){

        this.setState({ slotLoader: <ReactLoading type="bars" color="#000" height={50} width={35} /> });
        this.setState({ selectedSlot: slot });
     // await CartService.setAddress(add_name)
     // .then(res=>{  
      //    this.setState({ addressLoader: '' });
     //     this.setState({addressStatus:true})
     //     this.getCart()
      //    this.setState({ slotModal: false });
     // })
        this.setState({ slotLoader: '' });
        this.setState({ slotModal: false });
    }
}

async checkOut(e){

  let stat = true
  if(!this.state.addressStatus){
     alert("Please add shipping address to continue") 
     stat =false
  }
  if(!this.state.cartItems.length > 0){
     alert("Your Cart is empty")
     stat =false
  }

  if(this.state.loggedIn && stat == true){

      const user =  AuthService.getCurrentUser();
      this.setState({ checkoutLoader:'Proccessing Order...'});
      window.location.href = "/payment.html"
      /*await CartService.place_order()
      .then(res=>{  
            console.log(res)

            this.setState({ orderDetails:res});
           
            var options = {
              "key": 'rzp_test_v6nWNOJTSKQ7nu',
              "name": "",
              "description": "",
              'order_id':"",
              "handler": function(response) {
                  console.log(response);
                  var values ={
                      razorpay_signature : response.razorpay_signature,
                      razorpay_order_id : response.razorpay_order_id,
                      transactionid : response.razorpay_payment_id                }
                  
              },
              "prefill":{
                  "name":user.full_name,
                  "email":user.email,
                  "contact":user.mobile_no,
              },
              "notes": {
                "address": this.state.address
              },
              "theme": {
                "color": "#528ff0"
              }
            };

            
            console.log("res.data.id")
            console.log(res.message.id)
            if(res.message.id){
                this.setState({ checkoutLoader:'Order Placed.'});
               

                localStorage.setItem("orderID", res.message.id);
                window.location.href = "/payment.html"
              
            }
            

      })*/

  }
  

  
}

modalClose= () =>  {
  this.setState(prevState => ({
    addModal: !prevState.addModal
}));
}


render() {
  if(!this.state.address){
    this.setState({ address:'Please add shipping address'});
  }

  if(this.state.cartEmpty){
        return <Emptycart />
  }
  else{

    return <div>
    <div className="container mt-5 cart-page">
      <div className="row ">
       
        <div className="col-md-8 ">
        {this.state.cartItems.map((itm,j)=>{
            if(itm.item_group != 'Opt-In Items'){
              return( <Cartitem itm={itm} key={j} handleUpdate={this.handleUpdate} />)
            }        
        })}
    
        
        {this.state.optinItems.map((itm,k)=>{
          if(this.state.loggedIn && this.state.cartItems.length>0){
            return(
              <Optin itm={itm} cartitems={this.state.cartItems} key={k} handleUpdate={this.handleUpdate} />
            )
          }
        })}
         </div>
    
            <div className="col-lg-4 center-block mb-5">
                    <div className="container res-address">
                      <h3 className="delivery-detials"> Delivery Address</h3>
                            <div className=" btn btn-outlines ouline-space width-335">
                            
                            {(() => {
                                  if (this.state.loggedIn) {
                                      return (<>
                                        <div className="float-right"><p><a href="#" onClick={e => this.addressPop(e)} className="greeen-pen"><CreateOutlinedIcon  aria-hidden="true" type="button" /></a></p></div>                                    
                                        <h6 className="text-left">
                                          <ReactHtml html={this.state.address}/> 
                                        </h6>
                                        <Modal 
                                            size="md"
                                            isOpen={this.state.addModal}
                                          /*  toggle={this.handleClose} */
                                            show={this.state.addModal}
                                          /*  onHide={this.state.setPop} */
                                        >
                                        <ModalHeader toggle={() => this.setState({ addModal: false })}>
                                            <h3 className=" ml-2 address-titlt">Select Address</h3>
                                        </ModalHeader>
                                        <ModalBody>
                                        <div className="container ">
                                              {this.state.addressLoader}
                                              { this.state.shipAddress.map((itm,j)=>{
                                                return(<div key ={j} onClick={e => this.selectAddress(e, itm.name)}  className={(this.state.shipAddressName==itm.name)?"border-selected select-address btn btn-outlines col-12 ouline-space mr-2 close-green ": "select-address btn btn-outlines col-12 ouline-space mr-2  " } >
                                                      <h6 className="text-left"><ReactHtml html= {itm.display}/></h6>                                     
                                                </div>)
                                                })}
                              
                                          </div>
                                        </ModalBody>
                                        </Modal>
                                      </>)
                                  }  else {
                                  
                                  return (
                                    <p className="text-right total-price-small">Not Logged In</p>)
                                  }
                            })()}
                          
                            
                          </div> 
                        
                    </div>
            <div className="container res-address">
              <h3 className="delivery-detials"> Delivery Slot</h3>
              <div className=" btn btn-outlines ouline-space width-335">
              {(() => {
                        if (this.state.loggedIn) {
                            let tm = this.state.deliveryslot
                            if(tm){
                                    return (<> <div className="float-right "><p><a href="#" onClick={e => this.slotPop(e)} className="greeen-pen"><CreateOutlinedIcon  aria-hidden="true" type="button" /></a></p></div>
                                    <h6 className="address-header delevry-address mt-2"><Moment format="dddd, DD MMMM yyyy" withTitle>{this.state.selectedSlot }</Moment>
                                    <br /> {/* @ {tm[0].start_time} - {tm[0].end_time} */}
                                    </h6>

                                    <Modal 
                                            size="md"
                                            isOpen={this.state.slotModal}
                                          /*  toggle={this.handleClose} */
                                            show={this.state.slotModal}
                                          /*  onHide={this.state.setPop} */
                                        >
                                        <ModalHeader toggle={() => this.setState({ slotModal: false })}>
                                            <h3 className=" ml-2 address-titlt">Select slot</h3>
                                        </ModalHeader>
                                        <ModalBody>
                                        <div className="container ">
                                              {this.state.slotLoader}
                                              { this.state.deliveryslot.map((itm,j)=>{
                                                return(<div key ={j} onClick={e => this.selectSlot(e, itm)}  className={(this.state.selectedSlot==itm)?"border-selected select-address btn btn-outlines col-12 ouline-space mr-2 close-green ": "select-address btn btn-outlines col-12 ouline-space mr-2  " } >
                                                      <h6 className="text-left"><Moment format="dddd, DD MMMM yyyy" withTitle>{itm }</Moment></h6>                                     
                                                </div>)
                                                })}
                              
                                          </div>
                                        </ModalBody>
                                        </Modal>                                    
                                    
                                    </>
                                      )

                                      
                            }
                              
                            
                        }  else {
                        
                        return (
                          <p className="text-right total-price-small">Not Logged In</p>)
                        }
                    })()}
                   
                    {/*<h6 >Tomorrow,Sunday 13 December 2020</h6><br/>
                    <h6 className="address-header delevry-address">@04:00pm-06:00pm</h6><br/>*/}
                </div>
                    
            </div>
            {(() => {
                if (!this.state.coupon_code) {
                    return (<Coupondpdn handleUpdate={this.handleUpdate}/>)
                }
                else{
                    return( <div className="container coupn_section ">
                              <h3 className="delivery-detials"> Coupon</h3>
                              <div className=" btn btn-outlines ouline-space width-335 text-left">
                    
                                  <h5>Coupon Applied: <span>{this.state.coupon_code} </span></h5>  
                                  <a href="#" onClick={e => this.remvoveCoupon(e)}>Remove</a>
                              </div>
                            </div>)
                }
            })()}
            
            <div className="container  mt-5">
            <h3 className="delivery-detials">Cart Charges</h3>
                <div className="cart-total ml-3 mr-3 copn-inpt-box">  
            <ul className="subtotal-list copn-inpt-box">
                            <li className="float-price-right total-price-small bottom-space-small ">Sub-Total<span className="">Rs {this.state.cartTotal}</span></li>
                            <li className="float-price-right total-price-small bottom-space-small ">Wallet<img src="/assets/images/coin-icon.png" className="my-coin pl-1" alt="" title=""/><a className="bit-coin pl-1" href="/wallet.html" >Add Money</a><span className=" ">- Rs 0</span></li>
                            <li className="float-price-right total-price-small bottom-space-small">Discount<span className=" ">Rs {this.state.discount_amount}</span></li>
                            <li className="float-price-right total-price-small bottom-space-small">Delivery Charges <span className=" ">Rs {this.state.discount_amount}</span></li>
                            <li className="float-price-right total-price-small bottom-space-small">Tax <span className=" ">Rs {this.state.tax}</span></li>

                            <li className=" float-price-right total-price ">Total<span className=" ">Rs {this.state.cartGrandTotal}</span></li>
                        </ul>
                </div>
              <button type="button" className=" add-to-cart2 btnn-wide mt-3 chcek-out-btn text-center cp" onClick={e => this.checkOut(e)}>{this.state.checkoutLoader}</button></div>
        </div>    
      
      
    
      </div>
    </div>
    <div className="clearfix"></div>
    
    </div>
    

  }
    

}
}

export default Cart;

