import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './Harvestoftheday.css';
import Products from '../Components/Products';
class Similaritems extends React.Component {
    constructor(props){
        super(props);
       
    }
    
    render() {

        var settings = {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 6,
          slidesToScroll: 0,
          responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              }
            ]
        }
      return <div className="products-section">
                <div className="container">
                    
        <div className=" " >
        <Slider {...settings}>
              {this.props.value.map((itm,k)=>{
                if(k < 6){
                    return( <Products id={itm} key={k} /> )
                }
                else{  return( "" ) }
          })}
           
        </Slider>

        </div>
      </div>
    </div>
}}

export default Similaritems;