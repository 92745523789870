import React, {Component} from 'react';
import axios from 'axios';
import AuthService from '../Services/auth.service';
import './Wallettrans.css';
import Moment from 'react-moment';
import moment from 'moment';
import Breadcrumb from '../Components/Breadcrumb';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
class Wallettrans extends React.Component {
    constructor(props){
        super(props);
            this.state = {
               walt:[],Mmonth:''
            }
            this.handleChange = this.handleChange.bind(this);
        }



        componentDidMount(){
            const dateObj = new Date()
            const monthName = dateObj.toLocaleString("default", { month: "2-digit" })
           // this.setState({current:monthName})
            this.getData(monthName)
          }

          getData(Month){
          alert(Month)
            const USR_TOKEN = localStorage.getItem("userToken")
            if(USR_TOKEN){
               //alert(USR_TOKEN)
                const user =  AuthService.getCurrentUser();
                this.setState({profile:user})
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.wallet_transactions?month='+Month,{
            headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({walt:response.data['message']})
            console.log(response.data['message'])
        })
    }
    else{
        alert("Not Logged in")
    }
}
handleChange(event) 
        {
           //this.setState({Mmonth: event.target.value});
           this.setState({ismonth:true});
           //althisert(.state.Mmonth)
           //  alert(event.target.value)
           this.setState({Mmonth:event.target.value})
           this.getData(event.target.value)
           //event.preventDefault();
         }
  render() {
    return <div>
<div class="container">
<Breadcrumb first=" My Wallet"/>
{/* <nav aria-label="breadcrumb">
            <ol class="breadcrumb2 breadcrumb">
                <li class="breadcrumb"><a href="index.html"> Home&nbsp; </a></li>
                <li class="breadcrumb"> / My Wallet </li>
            </ol>
        </nav> */}
    <div class="row">
        <div class="col-md-4">
            <h2><a href="/wallet.html" type="button"><ArrowBackIcon className="mr-2"/></a>Wallet</h2>
        </div>
        <div class=" col-md-4 ">
        <p class="manage">You can manage your past orders here</p>
                    <h6  class="small-header">For the month of</h6>
                    
					
							
								<form class="form-inline">
									<div class="stepper-widget">
										<div class="">
                                        <select className="form-controls header-space "  value={this.state.Mmonth}  onChange={this.handleChange}  >
										    <option value="1"  >January</option>
										    <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
										    <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="" selected>October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
										    </select>
									    </div>
							        </div>
								</form>
                                {this.state.walt.map((itm,k)=>{
                                    if(itm.debit > itm.credit)
                                        var prc=itm.debit
                                    
                                    if(itm.debit < itm.credit)
                                         prc=itm.credit
                                             
                                   

                                   
                                            return <div class=" bottom-space bottom-line ">
                                            <span class="float-right ">{itm.debit ? -prc : +prc}</span>
                                           
                        
                                              <h6 class="opacity-header"><Moment format="DD MMMM yyyy" withTitle>{itm.posting_date}</Moment></h6>
                                                  <h6 class="purchase ">Purchase </h6>
                                                  <h6 class="Delivered ">Transaction successful</h6>
                                          </div>		
                
               
                    
                                                            })}   
                   
                    
                   
                    {/* <div class=" bottom-space bottom-line ">
                  <span class="float-right ">0</span>
              
                    <h6 class="opacity-header">01 september 2020</h6>
                        <h6 class="purchase "> purchase</h6>
                        <h6 class="transaction-failed ">Transaction Failed-Insuffient Balance</h6>
                    </div> */}
                    
                </div>
            </div>
        </div>
    </div>
    }
}

export default Wallettrans;