import React, {Component} from 'react';
import axios from 'axios';
import AuthService from '../Services/auth.service';
//import './Register.css';
class Register extends React.Component {

  constructor(props){
    super(props);
  
}

  render() {
    return <div>

<div className="row  ">


  <div className="   p-2 center-space">
    <div className="text-center">
      <a className="" href=""> <img src="/assets/images/logo.svg" alt="" title="" className="img-fluid" /> </a>
    </div>
        

                        <div class="row-form  mt-5 center-space  ">
                        <h6  class="green-label ">Name</h6>
                        <input class="form-controls " type="text" placeholder="" id="name"  onChange={e => this.name = e.target.value}/>

                        </div> 
                        <div class="row-form   center-space  mb-2">
                        <h6  class="green-label ">Mobile</h6>
                        <input class="form-controls " type="text" placeholder="" id="mobile"  onChange={e => this.mobileNo = e.target.value}/>

                        </div> 
                        <div class="row-form   center-space  mb-2">
                        <h6  class="green-label ">E-mail</h6>
                        <input class="form-controls " type="text" placeholder="" id="email"  onChange={e => this.email = e.target.value}/>

                        </div> 
                        <div className="text-center mb-5">
                            <button type="button" class=" add-to-cart2 btnn " onClick={() => this.props.updateState(this.name,this.mobileNo,this.email)}>Send OTP</button><br/>
                            <button type="button" class="  btnn  btn-outline-green" onClick={() => this.props.handleCancel('false')}>Cancel</button></div>



</div>




</div>







    </div>
    }
}

export default Register;
