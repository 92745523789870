import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './Subcategories.css';

class Subcategories extends React.Component {
    constructor(){
        super();
        this.state = {
            cats : []
        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.sub_item_groups?item_group='+this.props.value+'', {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cats:response.data['message']})
            
            })
        
    }
    render() {

        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 0
        }
      return <>
                <div className="clearfix"></div>
                <div className="subcat container mt-5">
                
                 
                        <h2 className="wow fadeInDown">{this.props.grp}</h2>

                        <div className="row subcategories">
                        
                            {this.state.cats.map((itm,k)=>{
                            return( 
                            <div key={k} className="col-lg-2 col-6 col-md-4 mb-4">

                                <div className="product">
                                    <a className="product-img" href={this.props.link}>
                                    <img src={process.env.REACT_APP_API_URL +itm.image} alt="" /></a>
                                    <h3 className="product-name">{itm.name}</h3>
                    
                                </div>
                        
                                                
                            </div>)
                        })}
                    
                        </div>
                        
                 
          </div>
    </>
}}

export default Subcategories;