import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch, useParams, HashRouter } from 'react-router-dom';
import './index.css';
import './Components/Navigation.css'
import Navigation from './Components/Navigation';
import Home from './Pages/Home';
import Cart from './Pages/Cart';
import Productlist from './Pages/Productlist';
import Categorylist from './Pages/Categorylist';
import Myprofile from './Pages/Myprofile';
import Myorders from './Pages/Myorders';
import Wallettrans from './Pages/Wallettrans';
import Wallet from './Pages/Wallet';
import Orderdetails from './Pages/Orderdetails';
import Footer from './Components/Footer';
import Productdetails from './Pages/Productdetails';
import Packageissue from './Pages/Packageissue';
import Payment from './Pages/Payment';
import Walletbalance from './Pages/Walletbalance';
import Contactus from './Pages/Contactus';
import Search from './Pages/Search';
import reportWebVitals from './reportWebVitals';
import {Helmet} from "react-helmet";
import Signup from './Pop-up/Signup';
import Signup2 from './Pop-up/Signup2';
import Complaintitem from './Pop-up/Complaintitem';
import Referafriend from './Pop-up/Referafriend';
import Congratulation from './Pop-up/Congratulation';
import Complaint from './Pop-up/Complaint';
import Address from './Pop-up/Address';
import Register from './Pop-up/Register';
import Makepayment from './Pop-up/Makepayment';
import WebFont from 'webfontloader';
import Pastorder from './Pages/Pastorder';
import Myprofileaddress from './Pop-up/Myprofileaddress';
import Refferr from './Pop-up/Refferr';
import Recentissue from './Pop-up/Recentissue';
import Success from './Pages/Success';


WebFont.load({
   google: {
     families: ['Nunito:300,400,700, 800', 'sans-serif']
   }
 });
ReactDOM.render(
  <React.StrictMode>

   
      <Router >
      <Navigation />
      
       <Switch>
              <Route exact path="/">
                  
                 <Home/>
              </Route> 
              <Route exact path="/index.html">
                 <Home/>
              </Route> 
              <Route exact path="/cart.html">
              
                 <Cart/>
              </Route> 
              <Route exact path="/past-order.html">
                 <Pastorder/>
              </Route>
              <Route exact path="/best-sellers">
                 <Productlist url="/method/wolly.api.best_sellers" title="Best Sellers"/>
              </Route>
              <Route exact path="/harvest-of-the-day">
                 <Productlist url="/method/wolly.api.harvest_of_the_day" title="Harvest of the day"/>
              </Route>  
              <Route exact path="/items-on-sale">
                 <Productlist url="/method/wolly.api.on_sale" title="Items on sale"/>
              </Route>
              <Route exact path="/package-issue.html">
                 <Packageissue/>
               </Route> 
               <Route exact path="/payment.html">
                 <Payment/>
               </Route>
               <Route exact path="/make-payment.html">
                 <Makepayment/>
               </Route>
               {/* <Route exact path="/search.html">
                 <Search/>
               </Route> */}
               <Route exact path="/search.html">
               <Productlist url="/method/wolly.api.best_sellers" title="Search Items"/>
               </Route>
              <Route exact path="/my-account.html">
                 <Myprofile/>
               </Route> 
              <Route exact path="/my-orders.html">
                 <Myorders/>
              </Route> 
              <Route exact path="/orders-details/:slug3">
                 <Orderdetails/>
              </Route>
              <Route exact path="/refferr.html">
                 <Refferr/>
              </Route> 
              <Route exact path="/my-profile-address.html">
                 <Myprofileaddress/>
              </Route>  
              <Route exact path="/wallet.html">
                 <Wallet/>
              </Route> 
              <Route exact path="/wallet-transfer.html">
                 <Wallettrans/>
              </Route> 
              <Route exact path="/wallet-balance.html">
                 <Walletbalance/>
              </Route> 
              <Route exact path="/contact-us.html">
                 <Contactus head="Contact us" title="Contact Us" url="contact-us"/>
              </Route>
              <Route exact path="/membership.html">
                 <Contactus head="Membership" title="Membership" url="membership"/>
              </Route>  
              <Route exact path="/partner-with-us.html">
                 <Contactus head="Partner with Us" title="Partner with Us" url="partner-with-us"/>
              </Route> 
              <Route exact path="/privacy-policy.html">
                 <Contactus head="Privacy Policy" title="Privacy Policy" url="privacy-policy"/>
              </Route>
              <Route exact path="/refund-policy.html">
                 <Contactus head="Refund Policy" title="Refund Policy" url="refund-policy"/>
              </Route>
              <Route exact path="/terms-of-service.html">
                 <Contactus head="Terms of Service" title="Terms of Service" url="terms-of-service"/>
              </Route>
              <Route exact path="/sign-up.html">
                 <Signup/>
              </Route> 
              <Route exact path="/sign-up2.html">
                 <Signup2/>
              </Route> 
              <Route exact path="/register.html">
                 <Register/>
              </Route> 
              <Route exact path="/refer-a-friend.html">
                 <Referafriend/>
              </Route> 
              <Route exact path="/recent">
                 <Recentissue/>
              </Route>
              <Route exact path="/success">
                 <Success/>
              </Route>
              <Route exact path="/congratulation.html">
                 <Congratulation/>
              </Route>
              <Route exact path="/complaint-item.html">
                 <Complaintitem/>
              </Route> 
              <Route exact path="/complaint.html">
                 <Complaint/>
              </Route> 
              <Route exact path="/address.html">
                 <Address/>
              </Route> 
              <Route path="/:slug/:slug1/:slug2">
                 <Productdetails/>
              </Route>
              <Route path="/:slug">
                 <Categorylist/>
              </Route>
              
       </Switch>
       <Footer />   
      </Router>
 
   </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
