import React, {Component} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Orderdetails.css';
import { withRouter } from "react-router";
import Complaintitem from '../Pop-up/Complaintitem';
import Breadcrumb from '../Components/Breadcrumb';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AuthService from '../Services/auth.service';
import Moment from 'react-moment';
import { Modal,ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Recentissue from '../Pop-up/Recentissue';
class Orderdetails extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cats : [],
            menu :[],
            rate :[],
            wallet :[],
            modal_large: false,
            showSignup:false,
            showOtp:false,
            setPop:false,
            showRegister:false,
            showLogin:true,
            showOTP:false,
            redirect:false,
            loading:'',
            uName:undefined,
            uMobile:undefined,
            uEmail:undefined,
            uNew:false,
            mmenu:'collapse navbar-collapse ',
            toggle:false,
            check:false,
            toggleLeft:false,
            toggleRight:false,
            mmRight:'collapse',
            mmLeft:'collapse',
            modal: false,modal1:false,
      name: "",
      modalInputName: "",
      profile:[],
      address:[],
      cats : [],issue:[],raise:[],id:[]
        }
       /*  this.tog_large = this.tog_large.bind(this); */
    
    }
    async componentDidMount(){
        
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
           //alert(USR_TOKEN)
            const user = await AuthService.getCurrentUser();
            this.setState({profile:user})
    const order = this.props.match.params.slug3;
    axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.get_order?name='+order,{
        headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({menu:response.data['message']})
        this.setState({rate:response.data['message']['items']})
        //console.log(response.data['message'])
    })
    axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.wallet_balance',{
        headers : {'Authorization' : USR_TOKEN,'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({wallet:response.data['message']})
        //console.log(response.data['message'])
    })
    axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.issue_list',{
        headers : {'Authorization' : USR_TOKEN,'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({issue:response.data['message']})
       // console.log(response.data['message'])
    })
    axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.raised_issues?order_id='+order,{
        headers : {'Authorization' : USR_TOKEN,'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({raise:response.data['message']})
       // console.log(response.data['message'])
    })
    }
    else{
    alert("Not Logged in")
    }
    }
     tog_large() {

       
    
    
            this.setState(prevState => ({
                modal: !prevState.modal
            }));
    
            this.setState(prevState => ({
                modal1: !prevState.modal1
            }));
      
        
    } 
    handleCancel = () => {
        this.tog_large()
    }
    handleSubmit(e) {
        this.setState({ name: this.state.modalInputName });
        this.modalClose();
      }
    
      modalOpen() {
        this.setState({ modal: true });
      }
    
      modalClose() {
        this.setState({
          modal_large: false
        });
    }



   
    handleOpen= Name =>{
        this.setState({ modal1: true });
        this.setState({ id:Name });
    }
    /* handleClose  = () => {
        this.tog_large()
    } */

    /* showRegister = () => {
        this.hideAll()
        this.setState({showRegister:true})
        
    }
    handleMobile = () => {
        this.hideAll()
        this.setState({showSignup:true})
       
    }
    handleCancel = () => {
        this.tog_large()
        this.hideAll()
        this.setState({showLogin:true})
    } */
  render() {
    return <div>
    <div class="container">
    <Breadcrumb first="My Orders"/>
       {/*  <nav aria-label="breadcrumb">
            <ol class="breadcrumb2 breadcrumb">
                <li class="breadcrumb"><a href="index.html"> Home&nbsp; </a></li>
                <li class="breadcrumb"> / My Orders </li>
            </ol>
        </nav> */}
        <div class="row">
            <div class="col-md-4">

            <h2><a href="/my-orders.html" type="button"><ArrowBackIcon className="mr-2"/></a>My orders</h2>

            

            </div>
            <div class=" col-md-6">
                <div>
                <p class="opacity-header">Ordered on&nbsp;<Moment format="DD MMMM yyyy" withTitle>{this.state.menu.transaction_date}</Moment></p>
                    <h4 class="delivery-today mt-1">{this.state.menu.status} </h4>
                    
                    <ul class="subtotal bottom-line subtotal-list subtotal ">
                    {this.state.rate.map((itm,k)=>{
            return(				
                    <li class=" float-price-right" key={k}><img src={process.env.REACT_APP_API_URL +itm.image} className="product-thumbnails" alt=""/><p className="itm ">{itm.item_name} x {itm.qty}</p> <span class="itm">₹{itm.net_rate}</span></li>
                    )
                    })}
                    </ul>
                </div>
                
                <div>
                    <ul class="subtotal-list ">
                        <li class="float-price-right total-price-small sub-total ">Sub-Total<span class="sub-total">Rs {this.state.menu.billed_amt}</span></li>
                        <li class="float-price-right total-price-small sub-total ">Discount<span class="sub-total ">Rs {this.state.menu.discount_amount}</span></li>
                        <li class="float-price-right total-price-small sub-total">Wallet<span class="sub-total ">Rs&nbsp;{this.state.wallet.balance}</span></li>
                        <li class=" float-price-right total-price ">Total<p class="per-delivery ">&nbsp;(per delivery)</p><span class=" ">Rs {this.state.menu.grand_total}</span></li>
                    </ul>
                    <button className="ordr-cncl">Cancel Order <ArrowForwardIcon/></button>
                </div>
                <br/>
                {/* <div>
                <p class="opacity-header">Ordered on 1september 2021 at 6.00pm </p>
                    <h4 class="delivery-today mt-1">Delivered Today,  02th at 4:00pm</h4>
                    
                    <ul class="subtotal bottom-line subtotal-list subtotal ">
                    <li class=" float-price-right "><img src="assets/images/product-img/product-img-1.jpg" class="product-thumbnails" alt=""/><p className="itm">Item Name x 1 </p> <span class="itm">$10</span></li>
                    <li class=" float-price-right "><img src="assets/images/product-img/product-img-1.jpg" class="product-thumbnails" alt=""/><p className="itm">Item Name x 1 </p> <span class="itm">$10</span></li>
                    <li class=" float-price-right "><img src="assets/images/product-img/product-img-1.jpg" class="product-thumbnails" alt=""/><p className="itm">Item Name x 1 </p> <span class="itm">$10</span></li>
                    <li class=" float-price-right "><img src="assets/images/product-img/product-img-1.jpg" class="product-thumbnails" alt=""/><p className="itm">Item Name x 1 </p> <span class="itm">$10</span></li>
                    </ul>
                </div>
                
                <div>
                    <ul class="subtotal-list ">
                        <li class="float-price-right total-price-small sub-total ">Sub-Total<span class="sub-total">Rs 40</span></li>
                        <li class="float-price-right total-price-small sub-total ">Discount<span class="sub-total ">Rs 10</span></li>
                        <li class="float-price-right total-price-small sub-total">Wallet<span class="sub-total ">Rs 0</span></li>
                        <li class=" float-price-right total-price ">Total<p class="per-delivery ">&nbsp;(per delivery)</p><span class=" ">Rs 30</span></li>
                    </ul>
                </div>
                <br/> */}
                {/* <div>
                  <h3>Order Options</h3>
                  <Link to="/package-issue.html"><button type="button" class=" add-to-cart2 btnn mb-3" onclick="/package-issue.html">Need Help?&nbsp;→</button></Link>
                    
                </div> */}
                <div>
                  <h3>Need Help?</h3>
                  
                    <ul class="subtotal-list ml-2">
                        <Modal
                                size="lg"
                                isOpen={this.state.modal}
                               /*  toggle={this.handleClose} */
                                show={this.state.modal}
                               /*  onHide={this.state.setPop} */
                            >
                            <ModalHeader toggle={() => this.setState({ modal: false })}>
                            </ModalHeader>
                            <ModalBody>
                                    <Complaintitem  modalClose={this.modalClose} handleCancel={this.handleCancel} head={this.state.rate}/>

                            </ModalBody>
                          
                            </Modal>

                            {this.state.issue.map((itms,s)=>{
            return(	
                        <li class=" mrn-btm" key={s}> <p className="green-link per-defined"><a href="#" onClick={e => this.modalOpen(e)}>{itms.name} </a></p></li>
                        )
                    })}
                    </ul>
                </div>
                <div>
                <h3> Recent issue</h3>
                  
                    <ul class="subtotal-list ml-2">
                    <Modal
                                size="lg"
                                isOpen={this.state.modal1}
                               /*  toggle={this.handleClose} */
                                show={this.state.modal1}
                               /*  onHide={this.state.setPop} */
                            >
                            <ModalHeader toggle={() => this.setState({ modal1: false })}>
                            </ModalHeader>
                            <ModalBody>
                                    <Recentissue   handleCancel={this.handleCancel} id={this.state.id}/>

                            </ModalBody>
                          
                            </Modal>
                    {this.state.raise.map((itmss,p)=>{
                        
            return(	
                        <li class="mrn-btm " key={p}> <p className="green-link per-defined"><a href="#" onClick={(Name) => this.handleOpen(itmss.name,p)}>{itmss.subject} </a></p></li>
                        )
                    })} 
                    </ul>
                </div> 
            </div>
            <div>
                <h1></h1> 
            </div>
        </div>
  </div>
</div>
}
}
export default withRouter (Orderdetails);