import axios from "axios";

 
const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  async login(username, password) {
    const loginData= {
        usr:username,
        pwd:password
    }
    return axios
      .post(API_URL + "api/method/login", loginData)
      .then(response => {
          //console.log(response.data)
        if (response.data) {
          const userData= { 
              full_name:response.data['full_name'],
              uid:loginData.usr
          }
          localStorage.setItem("user", JSON.stringify(userData));
          if(!localStorage.getItem("userType")){
                localStorage.setItem("userType", "Auth");
          }
        }

        return response.data;
      });
  }
  setToken(){
      let user = this.getCurrentUser()
      if(user){
            
      }
    
  }
  getIp(){
   // console.log(userlData)
       return axios.get(process.env.REACT_APP_API_URL + encodeURI('/api/method/windowworld.api.get_ip'), {
              headers : {'content-type': 'application/json'}})
              .then(response=>{  localStorage.setItem("userIP", response.data['message']) })
              .catch(err =>{ console.log(err)})
   
  }

  logout() {
		localStorage.removeItem("user");
    localStorage.removeItem("guestuser");
    localStorage.removeItem("userType");
    localStorage.removeItem("userToken");
    localStorage.removeItem('userIP');
    localStorage.removeItem('cart');
    localStorage.removeItem('servercart');

    window.location.href = "/"
    
  }
  async setMeta(urlRoute=''){

      return axios.get(process.env.REACT_APP_API_URL + encodeURI('api/resource/Website Route Meta'+ urlRoute ), {
      headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
      'content-type': 'application/json'}
      })
      .then(response=>{
          return response.data['data']['meta_tags']
      })

  }
  async register(name, mobile, email) {
        const userData= {
            full_name:name,
            email:email,
            mobile:mobile
        }

        return axios.post(API_URL + "api/method/wolly.api.user_register", userData, {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 'content-type': 'application/json'}}
                )
          .then(response=>{
                console.log("Registed")
                console.log(response.data['message'])
              const userData= { 
                    full_name:response.data['message'].full_name,
                    api_key:response.data['message'].api_key,
                    api_secret:response.data['message'].api_secret,
                    email:response.data['message'].email
                }
                  localStorage.setItem("user", JSON.stringify(userData))
                  localStorage.setItem("userType", 'Auth')
                  localStorage.setItem("userToken", 'token ' + response.data['message'].api_key + ":" +response.data['message'].api_secret)
                  
                })
          .catch(err =>{
            const msg={
              msg:'Email id already exists !',
              class: 'text-danger'
            }
            return msg
            return 
          
          })
    
  }
  
  switchUser(temUser=''){


  }
  async sendOTP(mobile=''){
    if(mobile){
      const userData= {
          mobile:mobile
      }
       
         return axios.post(API_URL + "/api/method/wolly.api.signup",userData,  {
           headers : {'Authorization' : process.env.REACT_APP_TOKEN, 'content-type': 'application/json'}}
         )
         .then(response=>{
            if(response.data['message']!='OTP Send')
             {  alert('OTP Error')}
             return response.data['message']
          })
          .catch(err =>{ console.log(err)})
    }
    else{
      alert("Invalid Mobile No..")
    }

  }
  async checkOTP(otp='',mobile='',name='',email=''){
    if(mobile){
      const userData= {
          mobile:mobile,
          otp:otp
      }
       
         return axios.post(API_URL + "api/method/wolly.api.verifyOTP",userData,  {
           headers : {'Authorization' : process.env.REACT_APP_TOKEN, 'content-type': 'application/json'}}
         )
         .then(response=>{
           // console.log(response.data['message'])

           if(response.data['message']=='verified'){
                const msg={
                  msg:'OTP Verified',
                  class: 'text-success'
                }
                return msg

               // this.register(name, mobile, email)
           }
           else if(response.data['message']=='no user'){
                const msg={
                  msg:'Mobile no not registared!',
                  class: 'text-danger'
                }
                return msg
             }
            else if(response.data['message']=='Invalid OTP'){
                const msg={
                  msg:'Invalid OTP!',
                  class: 'text-danger'
                }
                return msg
             } 
           else{
              const userData= { 
                full_name:response.data['message'].full_name,
                api_key:response.data['message'].api_key,
                api_secret:response.data['message'].api_secret,
                email:response.data['message'].email,
                mobile_no:response.data['message'].mobile_no
              }
              localStorage.setItem("user", JSON.stringify(userData))
              localStorage.setItem("userType", 'Auth')
              localStorage.setItem("userToken", 'token ' + response.data['message'].api_key + ":" +response.data['message'].api_secret)
              const msg={
                msg:'Verified & logging in ...',
                class: 'text-success'
              }
              return msg
              
           }
                    
        })
          .catch(err =>{ alert("Invalid OTP")})
          
    }
    else{
      alert("Invalid Mobile No..")
    }

  }
  async updateProfile(addressData) {

    const USR_TOKEN = localStorage.getItem("userToken")
    if(USR_TOKEN){
         
      const user =  this.getCurrentUser();
      return axios.post(process.env.REACT_APP_API_URL+'api/method/wolly.api.updateProfile', addressData,{
            headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
      }).then( 
          res =>{ return res }
      )
      .catch(
          err =>{ return err }
      )
    }
    else{
        alert("Not Logged in")
        this.logout()
    }
  }
  async guestRegister() {
    
    if(!localStorage.getItem('userIP')){
        AuthService.getIp();
    }
    
    let userIP = localStorage.getItem('userIP');
    
    const userData= {
        mobile:userIP,
        full_name:userIP,
        email:userIP + '@demouser.com'
    }
    
              return axios.post(API_URL + "/api/method/windowworld.api.user_register", userData, {
                              headers : {'Authorization' : process.env.REACT_APP_TOKEN, 'content-type': 'application/json'}}
                      )
                .then(response=>{
                    const userData= { 
                          full_name:'Guest User',
                          api_key:response.data['message'].api_key,
                          api_secret:response.data['message'].api_secret,
                          email:response.data['message'].email
                      }
                        localStorage.setItem("guestuser", JSON.stringify(userData))
                        localStorage.setItem("userType", 'guest')
                        localStorage.setItem("userToken", 'token ' + response.data['message'].api_key + ":" +response.data['message'].api_secret)
                       
                      })
                .catch(err =>{ console.log(err)})
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  getserverCartitems(){
    return JSON.parse(localStorage.getItem('servercart'));
  }
  getCartitems(){
    return JSON.parse(localStorage.getItem('cart'));
  }
  getGuestUser() {
    return JSON.parse(localStorage.getItem('guestuser'));
  }
  randomString() {
      let length=6, chars='0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
      return result;
  }
  clearStorage(){
      localStorage.removeItem("user");
      localStorage.removeItem("guestuser");
      localStorage.removeItem("userType");
      localStorage.removeItem("userToken");
      localStorage.removeItem('userIP');
      localStorage.removeItem('cart');
      localStorage.removeItem('servercart');

  }
  showStorage(){
      console.log("user")
      console.log(localStorage.getItem("user"));
      console.log("guestuser")
      console.log(localStorage.getItem("guestuser"));
      console.log("userType")
      console.log(localStorage.getItem("userType"));
      console.log("userToken")
      console.log(localStorage.getItem("userToken"));
      console.log("userIP")
      console.log(localStorage.getItem('userIP'));
      console.log("servercart")
      console.log(localStorage.getItem('servercart'))
  }
}

export default new AuthService();
