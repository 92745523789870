import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './Middleslider.css';

class MiddleSlider extends React.Component {
    constructor(){
        super();
        this.state = {
            slider : []
        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.web_second_slider', {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({slider:response.data['message']})
            
            })
        
    }
    render() {

        var settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: false,
            loop: true,
            dots: true,
            arrows: true,
          
        }
      return  <div className="container">
    <div className="middle-slider ">
          <div className="hero-slider-active-2 nav-style-1 nav-style-1-green">
          <Slider {...settings}>
            {this.state.slider.map((itm,p)=>{
            return(
                    
                    <div key={p} className="slider-item" >
                        
                        <img src={process.env.REACT_APP_API_URL + itm.image} rel="preload" width="100%"/>
                        
                    </div>)
            })}
            </Slider>
      </div>
    </div>
  </div>
}}

export default MiddleSlider;