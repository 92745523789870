import React, {Component} from 'react';
import axios from 'axios';
import './Signup2.css';

import AuthService from '../Services/auth.service';
class Signup2 extends React.Component {

constructor(){
    super();
    this.state = {
        cats : [],
    }
  // this.signUp = this.signUp.bind(this);
}
 
onlyNumberKey(evt) {
          
  // Only ASCII character in that range allowed
  var ASCIICode = (evt.which) ? evt.which : evt.keyCode
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
      return false;
  return true;
}
  render() {
    return <div>

                <div className="row  ">


                  <div className="   pt-5 pb-5 center-space">
                    <div className="text-center container">
                    <a className="" href=""> <img src="/assets/images/logo.svg" alt="" title="" className="img-fluid mb-5" /> </a><br/>
                      <a className="" href=""> <img src="/assets/images/sign2.png" alt="" title="" className="img-fluid mb-2" /> </a><br/>
                              <h6 class="green-label text-left ml-4">Mobile Number</h6>
                              <input class="form-controls frn--ctr ml-4" type="text" placeholder="" maxlength="10" id="example-email-input" onChange={e => this.mobileNo = e.target.value}/>
                              <p class={this.props.msgClass}>{this.props.msg}</p>
                     </div> 
                      <div className="text-center top-up">
                            <button type="button" class=" add-to-cart2 btnn " onClick={() => this.props.updateState(this.mobileNo)}>Send OTP</button><br/>
                            <button type="button" class="  btnn  btn-outline-green" onClick={() => this.props.handleCancel('false')}>Cancel</button>
                      </div>
                           
                    </div>
                            
                </div>
    </div>
    }
}

export default Signup2;
