import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './Harvestoftheday.css';
import Products from '../Components/Products';
class Bestsellers extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cats : []
        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.item_list?item_group='+this.props.group+'', {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cats:response.data['message']})
                console.log(response.data['message'])
                
            })
            .catch(err=>{ console.log(err)})
        
    }
    render() {

        var settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 0,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
              ]
        }
      return <div className="products-section">
      <div className="container">
        <div className="d-flex justify-content-between">
                <h2 className="fadeInDown ">{this.props.head} </h2>
                <div className="seeAll" ><a href={this.props.link}>See All</a></div> 
        </div>
        <div className=" " >
        <Slider {...settings}>
              {this.state.cats.map((itm,k)=>{
           
                return( <Products id={itm} key={k} />
           )
          })}
           
        </Slider>

        </div>
      </div>
    </div>
}
}

export default Bestsellers;