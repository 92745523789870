import React, {Component} from 'react';
import axios from 'axios';
import './Myorders.css';
import AuthService from '../Services/auth.service';
class Myorders extends React.Component {
    constructor(props){
        super(props);
            this.state = {
               pack:[]
            }
        }
        async componentDidMount(){
            const USR_TOKEN = localStorage.getItem("userToken")
            if(USR_TOKEN){
               //alert(USR_TOKEN)
                const user = await AuthService.getCurrentUser();
                this.setState({profile:user})
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.issue_list',{
            headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({pack:response.data['message']})
            console.log(response.data['message'])
        })
    }
    else{
        alert("Not Logged in")
    }
}
    render() {
        return <div className="container  mb-3">
            <nav aria-label="breadcrumb ">
            <ol class="breadcrumb2 breadcrumb brdcum">
                <li class="breadcrumb"><a href="index.html"> Home&nbsp; </a></li>
                <li class="breadcrumb"> /&nbsp;Package Issue</li>
            </ol>
            </nav>
           <div className="row mt-2" >
           <div className="col-md-3">
            <h7  class="green-label ">To Deliver and Bill </h7>
            <h6  className="small-header opacity-header">28 Sep 2020</h6>
            
        
            <ul class="subtotal subtotal-list subtotal ">
                    <li class=" float-price-right "><p>Order ID</p><span class="small-header opacity-header">Order Price</span></li>
                    <li class=" float-price-right "><p>SO-WOO-00830</p><span class="small-header opacity-header">606</span></li>
            </ul>
        </div>
       
        <div className="col-md-6" >
            <h3>Need Help</h3>
            {this.state.pack.map((itm,k)=>{
            return(	
            <button type="button" className=" add-to-select btn-curve btn btn-outline-success waves-effect waves-light tag-btn form-controls-radius">{itm.name}</button>
            
            )
        })}   

            </div>
        </div>
        </div>
        
}
}

export default Myorders;