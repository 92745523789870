import React, {Component} from 'react';
import axios from 'axios';
import './Wallet.css';
import Breadcrumb from '../Components/Breadcrumb';
import AuthService from '../Services/auth.service';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Makepayment from '../Pop-up/Makepayment';
import { Modal,ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
class Wallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu : '[]',
            modal: false,
            amount:'',
            payButton:'Next'
    
        }
        this.onAmountChange = this.onAmountChange.bind(this)
    }
    async componentDidMount(){
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
           //alert(USR_TOKEN)
            const user = await AuthService.getCurrentUser();
            this.setState({profile:user})
                axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.wallet_balance',{
                    headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
                })
                .then(response=>{
                    this.setState({menu:response.data['message']})
                    console.log(response.data['message'])
                    })
                }
        else{
            alert("Not Logged in")
            }
    }

    modalOpen() {
        
        this.setState({ modal: true });
    }

    onAmountChange(e){
         const {name, value} = e.target;
         if(value > 0){
            this.setState({ amount: value });
         }
         
   
    }
    async addAmuont (e, amount){
        e.preventDefault();
        if(amount > 0){
            this.setState({ amount: amount });
        }
            
    }
  render() {
    return <div>
        <div class="container">
        <Breadcrumb first=" My Wallet"/>
            {/* <nav aria-label="breadcrumb">
            <ol class="breadcrumb2 breadcrumb brdcum">
                <li class="breadcrumb"><a href="index.html"> Home&nbsp; </a></li>
                <li class="breadcrumb"> / My Wallet</li>
            </ol>
            </nav> */}
            <div class="row">
                <div class="col-md-4">
                    <h2>Wallet</h2>
                </div>
                <div class=" col-md-5 ">
                        <p class="manage">You can manage and set your profile information here</p>
                        
                        <h3>Add Money to Wallet</h3>
                        <div class="row-form">
                        <input class="form-controls" id="amountInput" type="text" onChange={this.onAmountChange} defaultValue={this.state.amount} placeholder="Enter Amount" id="example-email-input"/>
                        </div>  
                        <div class="tag_bottom">
                            <button type="button" class=" add-to-select btn-width btn-outline-success waves-effect waves-light tag-btn"  onClick={(e) => { this.addAmuont(e, '100');}}><u>₹100</u></button>
                            <button type="button" class=" add-to-select btn-width btn-outline-success waves-effect waves-light tag-btn" onClick={(e) => { this.addAmuont(e, '500');}}><u>₹500</u></button>
                            <button type="button" class=" add-to-select btn-width btn-outline-success waves-effect waves-light tag-btn" onClick={(e) => { this.addAmuont(e, '1000');}}><u>₹1000</u></button>
                        </div>
                        <a href="#"><button type="button" class=" add-to-cart2 btnn mt-31" onClick={e => this.modalOpen(e)}>{this.state.payButton}</button></a>
                                    <Modal
                                        size="lg"
                                        isOpen={this.state.modal}
                                        /*  toggle={this.handleClose} */
                                        show={this.state.modal}
                                        /*  onHide={this.state.setPop} */
                                    >
                            
                            <ModalHeader toggle={() => this.setState({ modal: false })}>
                            </ModalHeader>
                            <ModalBody>
                                    <Makepayment />

                            </ModalBody>
                          
                            </Modal>
                        <br/>
                        <br/>
                        <br/>
                        <h3> Wallet Options</h3>
                        
                        <p className="green-link my-link mb-5"><a href="/wallet-transfer.html">Show All Transaction History <ArrowForwardIcon/></a></p>
                    
                </div>
                <div class=" col-md-3 wallet-balance"> 
                <span className="price">Current Wallet Balance<p>₹&nbsp;{this.state.menu}</p></span>
                </div>
                </div>
    </div>
</div>

    }
}
export default Wallet;