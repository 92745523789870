import React, {Component} from 'react';
import axios from 'axios';
import './Payment.css';
import Breadcrumb from '../Components/Breadcrumb';

import AuthService from '../Services/auth.service';
import CartService from '../Services/cart.service';
class Payment extends React.Component {
    constructor(props){
       
        super(props);
        this.state = {
            cartItems: [],
            cartTotal: [],
            cartGrandTotal: [],
            address:[],
            wallet:0,
            checkoutLoader:'Place Order'
            }
        }


    async componentDidMount(){
        this.getCart()
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    
    }
    async placeOrder(e){
        e.preventDefault();   
        const user =  AuthService.getCurrentUser();


        if(!this.state.orderDetails){
            await CartService.place_order()
            .then(res=>{  
                  console.log(res)
      
                  this.setState({ orderDetails:res});
                 
                  var options = {
                    "key": 'rzp_test_v6nWNOJTSKQ7nu',
                    "name": "",
                    "description": "",
                    'order_id':"",
                    "handler": function(response) {
                        console.log(response);
                        var values ={
                            razorpay_signature : response.razorpay_signature,
                            razorpay_order_id : response.razorpay_order_id,
                            transactionid : response.razorpay_payment_id                }
                        
                    },
                    "prefill":{
                        "name":user.full_name,
                        "email":user.email,
                        "contact":user.mobile_no,
                    },
                    "notes": {
                      "address": this.state.address
                    },
                    "theme": {
                      "color": "#528ff0"
                    }
                  };
      
                  
                  console.log("res.data.id")
                  console.log(res.message.id)
                  if(res.message.id){
                      this.setState({ checkoutLoader:'Order Placed.'});
                      options.order_id = res.message.id;
                      options.amount = this.state.cartTotal*100;
                      //console.log("options")
                      //console.log(options)
      
                      localStorage.setItem("orderID", res.message.id);
                     // window.location.href = "/payment.html"
                      var rzp1 = new window.Razorpay(options);
                      rzp1.open();
                      this.setState({ checkoutLoader:'Processing Payment...'});
      
                  }
                  
      
            })

        }
        
            
      
      
        
      
        
      }
      async getCart(){
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
     
          await CartService.getCart()
          .then(response=>{ 
              
              this.setState({cartItems:response['doc']['items']})
              this.setState({cartTotal:response['doc']['total']})
              this.setState({cartGrandTotal:response['doc']['total']})
              if(response['doc']['shipping_address']){
                this.setState({address:response['doc']['shipping_address']})
                }
          })
          .catch(err=>{ console.log(err)})

              axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.wallet_balance',{
                  headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
              })
              .then(response=>{
                  this.setState({wallet:response.data['message']})

              })
              
      
          
      
        }
    
      
      }

    render() {
        if (!this.state.cartGrandTotal > 0){
            window.location.href = "/cart.html"
        }

        return<div class="container mt-2 mb-3">
            <Breadcrumb first=" My Payment"/>
                    <h2>Make Payment</h2>
                    <div className="row">
                        <div className="col-md-6 mt-2 mb-3">
                            <h6  className="small-header">Amount</h6>
                            <h2 className="total-price">Rs {this.state.cartGrandTotal}</h2>
                            {(() => {
                                  if (this.state.wallet > 0) {
                                    return (<> <h6  className="small-header pt-4">Use Wallet Amount</h6>
                            
                                            <input value="wallet" type="radio" name="s"/>&nbsp;&nbsp;&nbsp;<label for="yes">Rs 43.99 of Rs {this.state.wallet}0</label>
                                    </>) 
                                }
                            })()}
                            </div>
                        <div className="col-md-4 mt-2 mb-3">
                        <h6  className="small-header">Choose Other Payment Option</h6>
                        <ul className="subtotal subtotal-list">
                            <li className="subtotal"><input value="Credit Card" type="radio" name="s"/><p>Credit Card</p> </li>
                            <li className="subtotal"><input type="radio" name="s"/><p>Debit Card</p> </li>
                            <li className="subtotal"><input type="radio" name="s"/><p>UPI</p> </li>
                            <li className="subtotal"><input type="radio" name="s"/><p>Cash on Delivery</p> </li>
                        </ul>
                        <button type="button" class=" add-to-cart2 btnn mybtn" onClick={e => this.placeOrder(e)}>{this.state.checkoutLoader}</button>
                        </div>
                    </div>
                </div>    
          
        
        
         
    }       
}

export default Payment;