import React, {Component} from 'react';
import './Signup.css';
class Signup extends React.Component {
  
  render() {
    return <div>

  <div className="container ">

      <div className="text-center p-5">
            <a className="" href=""> <img src="/assets/images/logo.svg" alt="" title="" className="img-fluid mb-5" /> </a><br/>
            <a className="" href=""> <img src="/assets/images/sign2.png" alt="" title="" className="img-fluid mb-5" /> </a><br/>
            <button type="button" class=" add-to-cart2 btnn " onClick={() => this.props.updateState('login')}>Log In</button>
            <br/>
            <button type="button" class="  btnn  btn-outline-green " onClick={() => this.props.handleRegister()}>Sign Up</button>
      </div>

  </div>


    </div>
    }
}

export default Signup;
