import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import HomeSlider from '../Components/HomeSlider'
import Categories from '../Components/Categories';
import Banner from '../Components/Banner';
import Harvestoftheday from '../Components/Harvestoftheday';
import Bestsellers from '../Components/Bestsellers';
import MiddleSlider from '../Components/Middleslider';
import Subcategories from '../Components/Subcategories';
import AuthService from '../Services/auth.service';



class Home extends React.Component {

    componentDidMount(){
      //AuthService.clearStorage()
      AuthService.showStorage()
    }
    
    render() {
         return <div>
                   <Categories />
                    <HomeSlider />
                    
                    <Harvestoftheday head="Harvest of the day" url="wolly.api.harvest_of_the_day" link="/harvest-of-the-day"/>
                    <Harvestoftheday head="Best Sellers in Exotic Fruits" url="wolly.api.best_sellers?item_group=Exotic Fruits" link="fresh-fruits/exotic-fruits" />
                    <Harvestoftheday head="Best Sellers in Spices" url="wolly.api.best_sellers?item_group=Spices" link="must-haves/spices"/>
                    <Harvestoftheday head="Items on Sale" url="wolly.api.on_sale" link="/items-on-sale"/>
                    <Banner />
                    {/* <Bestsellers grp="Best Sellers in Fresh Fruits" url="wolly.api.item_list?item_group=Exotic Fruits&sort=desc" link="/fresh-vegetables"/>
                    <Bestsellers grp="Best Sellers in Vegetables" url="wolly.api.item_list?item_group=Exotics&sort=desc"  link="/fresh-fruits"/>
                    <MiddleSlider /> */}
                    <Subcategories grp="Vegetables" value="Vegetables"  link="/fresh-vegetables"/>
                    <Subcategories grp="Must Haves"  value="Must Haves" link="/fresh-fruits"/>
                
                </div>
    }
}
export default Home;
