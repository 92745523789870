import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './Harvestoftheday.css';
import Products from './Products';
import { Helmet } from 'react-helmet';
class List extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        
        }
        
    }
    
   
    render() {

       // console.log(this.state.pdt)
      return <>
          <div className="row">
           <h2 class="wow fadeInDown text-center w-100">{this.props.grp}</h2>
           </div>
           <div className="row">
              {this.props.best.map((itm,k)=>{
           
                return( <Products id={itm} key={k} />
           )
          })}
          </div>
             </>
             
}}

export default List;