import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../Services/auth.service';
class Demowallet extends React.Component {
    
constructor(){
    super();
        this.state = {
           ord:[]
        }
    }
    async componentDidMount(){
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
           //alert(USR_TOKEN)
            const user = await AuthService.getCurrentUser();
            this.setState({profile:user})
    axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api/method/wolly.api.wallet_balance',{
        headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({ord:response.data['message']})
        console.log(response.data['message'])
    })
}
else{
   // alert("Not Logged in")
}
}
      render() {
          //alert(this.state.amt)
           return <div class="row wallet-balance">
           <div class="col-md-4">
               <h2>Wallet</h2>
           </div>
           <div class=" col-md-4 ">
                   <p class="">You can manage and set your profile information here</p>
                   
                   <h3>Add Money to Wallet</h3>
                   <div class="row-form">
                   <input class="form-controls" type="text" placeholder="Enter Amount" id="example-email-input"/>
                   </div>  
                   <div class="tag_bottom">
                       <button type="button" class=" add-to-select btn-width btn-outline-success waves-effect waves-light tag-btn"><u>₹100</u></button>
                       <button type="button" class=" add-to-select btn-width btn-outline-success waves-effect waves-light tag-btn"><u>₹500</u></button>
                       <button type="button" class=" add-to-select btn-width btn-outline-success waves-effect waves-light tag-btn"><u>₹100</u></button>
                   </div>
                   <button type="button" class=" add-to-cart2 btnn mt-31">Next</button>
                   <br/>
                   <br/>
                   <br/>
                   <h3> Wallet Option</h3>
                   
                   <p className="green-link"><a href="/wallet-transfer.html">Show All Transaction Histroy→ </a></p>
               
           </div>
           <div class=" col-md-4 wallet-balance "> 
           <span className="price">current wallet balance<p>₹{this.state.amt}</p></span>
           </div>
           </div>
           }
}

export default Demowallet;