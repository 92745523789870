import React, {Component} from 'react';
import axios from 'axios';
import { withRouter } from "react-router";
import './List.css';
import Categorysub from '../Components/Categorysub'
import Similaritems from '../Components/Similaritems'
import {Helmet} from "react-helmet";
import Listproduct from "../Components/Listproduct"
import Breadcrumb from '../Components/Breadcrumb';
import Categories2 from '../Components/Categories2';
class Categorylist extends React.Component {
	constructor(props){
        super(props);
        this.state = {
            cats : [],menucate :[],
            
        }
    }
    componentDidMount(){
        const route = this.props.match.params.slug;

//alert(route)
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.get_item_group_Byroute?route='+route, {
                        headers : {'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cats:response.data['message']})
                this.setState({menucate:response.data['message']})
             // console.log(response.data['message'])
            })
            .catch(err=>{ console.log(err)})
    }
    
    handleUpdate = (name) => {
      
    
     // this.setState({cats:newCat})
      //console.log(newCat)
      //alert(name)
      axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.groupname_byroute?route='+name, {
        headers : {'content-type': 'application/json'}
        })
        .then(response=>{
          const newCat = [];
          newCat[0] = { name:response.data['message']}
          this.setState({cats:newCat})
        //this.setState({menucate:response.data['message']})
        // console.log(response.data['message'])
        })
        .catch(err=>{ console.log(err)})
            }


  render() {
    //alert(this.state.menucate)
    //console.log(this.state.cats)
    return <>
       <Categorysub data={this.state.menucate}  />
<div className="container pro-list">
  {/*<Breadcrumb first={this.handleUpdate} second=""/> */}
	
		
	 
										
  	<div className="row">                                    
   
    
        <div className="col-md-12">
          
            <div className="clearfix"></div>
            <div id="products" className="row view-group">
            
           
              <div className="container">
                  {this.state.cats.map((itm,k)=>{
                    
                    return(
                          <Listproduct key={k} kval={k} value={itm.name}   />
                  )
                })}
                  <div className="container mb-5 ">
                      <div className=" banner banner1 ">
                          <img src="/assets/images/be.jpg" alt="" className="ban-er"/>
                      </div>
                  </div>
              </div>
		        </div>
	      </div>
	
        <div className="clearfix"></div>
        </div>

  </div>
  <div className="clearfix"></div>
 
</>




 
}
}

export default withRouter(Categorylist);
