import React, {Component} from 'react';
import ShareIcon from '@material-ui/icons/Share';
import './Congratulation.css';
class Referafriend extends React.Component {
  
  render() {
    return <div>

  <div className="container ">

      <div className="text-center p-5">
            <a className="" href=""> <img src="/assets/images/logo.svg" alt="" title="" className="img-fluid pop-img" /> </a><br/>
            <a className="" href=""> <img src="/assets/images/welcome.png" alt="" title="" className="img-fluid mb-3 mt-1 pop-img1"/> </a><br/>
            <a className="" href=""> <img src="/assets/images/sign2.png" alt="" title="" className="img-fluid mb-3" /> </a><br/>
            <h1 className="refer">Congratulations</h1>
            <p class="point mb-4"><b class="bld">$50</b>added to your wallet</p>
            <p class="point">Use your unique code while signing up to get <b class="bld">$50</b> as wallet credit for both you and your friend</p>
            <br/>
            <div className=" ">
          <input type="text-bdr" className=""  placeholder="" id=""/>
          </div>
          <div className=" ">
            
              <li class="copy green-link share mt-3"><a href=""><img src="/assets/images/copy.png" alt="" title="" className="img-fluid mr-1"/>Copy Unique Code</a></li>
              <li class="copy green-link share mt-2"><a href=""><ShareIcon className="mr-1"/>Share download link</a></li>
          
          </div>
          <div className=" ">
          <button type="button" class=" add-to-cart2 btnn next-btn mt-4">Next</button>
          </div>
      </div>

  </div>


    </div>
    }
}

export default Referafriend;
