import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './Harvestoftheday.css';
import Products from '../Components/Products';
class Listproduct extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            pdt : []
        }
        
    }


    componentDidMount(){
     
           
            axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.item_list?item_group='+this.props.value+'&sort=desc', {
                            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                        'content-type': 'application/json'}
                })
                .then(response=>{
                    this.setState({pdt:response.data['message']})
                    console.log(response.data['message'])
                })
                .catch(err=>{console.log(err)})
                
        }
    componentDidUpdate(prevProps) {
            // Typical usage (don't forget to compare props):
            if (this.props.value !== prevProps.value) {
               
                axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.item_list?item_group='+this.props.value+'&sort=desc', {
                    headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
                    })
                    .then(response=>{
                        this.setState({pdt:response.data['message']})
                    
                    })
                    .catch(err=>{console.log(err)})
            }
          }
   
    render() {

       // console.log(this.state.pdt)
      return <div className="container cat-items">
          <div className="row" id={"cat" + this.props.kval}>
           <h2 className="wow fadeInDown text-center w-100">{this.props.value}</h2>
           </div>
           <div className="row">
              {this.state.pdt.map((itm,k)=>{
           
                return( <Products id={itm} key={k} />
           )
          })}
          </div>
             </div>
}}

export default Listproduct;