import axios from "axios";
import AuthService from "./auth.service";


const API_URL = process.env.REACT_APP_API_URL;

class CartService {
  async add_item(itemData) {
      const USR_TOKEN = localStorage.getItem("userToken")
      if(USR_TOKEN){
         
        return axios
          .post(API_URL + "api/method/wolly.api.update_item", itemData, {
            headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}}
            )
          .then(response => {
              console.log(response.data)
              this.update_cart()
          })
          .catch(function (error) {
                if(error['message']=='Network Error'){
                    AuthService.logout()
                    alert("Logged Out")
                }
            })
      
      }
  }
    async place_order(){
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
            let qoute = { qoute: 'Deo'}
            return axios.post(process.env.REACT_APP_API_URL + encodeURI('api/method/wolly.api.order_place'), qoute, {
            headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
            })
            .then(response=>{
                console.log('Order Placed')
                return response.data
            })

        }
    }
    
    async processPayment(){
       

    }

    async transfer_cart(){    //transfer local cart items to server
        const delay = ms => new Promise(res => setTimeout(res, ms));

        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
            try{
                let cart = AuthService.getCartitems()
                if(cart.length > 0){
                        for (let itm in cart) {
                            const itemData= { 
                                item_code:cart[itm]['item_code'],
                                qty:cart[itm]['qty'],
                            }
                            this.add_item(itemData)
                            //await delay(2000);
                            console.log("Item Addes: " + cart[itm]['item_code'] )
                            console.log("----------------------" )
                        }
                        localStorage.removeItem('cart');
                }       
            }
            catch(er){ //alert ("Cart Error.#2001");  
            }

        }
    }

    async update_cart(){
      

        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
            axios.get(process.env.REACT_APP_API_URL + encodeURI('api/method/wolly.api.get_cart_count'),{
                headers : {'Authorization' : USR_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                document.getElementById("pro-count").innerText = response.data['message'][0]['count']
                document.getElementById("fpro-count").innerText = response.data['message'][0]['count']
                document.getElementById("cart-amount").innerText = response.data['message'][0]['total']
                if(window.location.pathname === '/cart.html'){
                    document.querySelector(".footer-cart").style.setProperty("display","none"); 
                }else{
                    if(response.data['message'][0]['count'] > 0){
                        document.querySelector(".footer-cart").style.setProperty("display","block");
                    }
                    else{ 
                        document.querySelector(".footer-cart").style.setProperty("display","none");
                    }

                }
                
            })
            .catch(function (error) {
                if(error['message']=='Network Error'){
                    AuthService.logout()
                    alert("Logged Out")
                }
            })
            
        }
        else{
           
            let cart = AuthService.getCartitems()
            if(cart){
                let count = cart.length
                let total = parseFloat(0.00);
                if(cart.length > 0){
                        for (let itm in cart) {
                            total += parseFloat(cart[itm]['rate']);
                        }
                        document.querySelector(".footer-cart").style.setProperty("display","block");
                }
                else{ 
                    document.querySelector(".footer-cart").style.setProperty("display","none"); 
                
                }
        
                document.getElementById("pro-count").innerText = count
                document.getElementById("fpro-count").innerText = count
                document.getElementById("cart-amount").innerText = total  

            }
            else{
                document.querySelector(".footer-cart").style.setProperty("display","none"); 
            }
            
        }
        
        if(window.location.pathname === '/cart.html'){
            document.querySelector(".footer-cart").style.setProperty("display","none"); 
        }
    }
  async update_cart_local(){
    try{
        let cart = AuthService.getCartitems()
        let count = cart.length
        let total = parseFloat(0.00);
        if(cart.length > 0){
        
                for (let itm in cart) {
                    total += parseFloat(cart[itm]['rate']);
                }
                document.querySelector(".footer-cart").style.setProperty("display","block");
        }
        else{ 
            document.querySelector(".footer-cart").style.setProperty("display","none"); 
        
        }

        document.getElementById("pro-count").innerText = count
        document.getElementById("fpro-count").innerText = count
        document.getElementById("cart-amount").innerText = total  
        if(window.location.pathname === '/cart.html'){
            document.querySelector(".footer-cart").style.setProperty("display","none"); 
        }

    }
    catch(er){ console.log(er)}

 }

  async updateProfile(userData){

      
      const USR_TOKEN = localStorage.getItem("userToken")
      if(USR_TOKEN){
          return axios.post(API_URL + "/api/method/windowworld.api.updateProfile", userData, {
                headers : {'Authorization' : USR_TOKEN,  'content-type': 'application/json'}}
          )
          .then(response=>{
                console.log(response)
          })
          .catch(err =>{ console.log(err)})
        }
  }
  async removeItem(item_code){

    const USR_TOKEN = localStorage.getItem("userToken")
    if(USR_TOKEN){
        let itemData={
            item_code:item_code, 
            qty: 0
          }

            let cart = AuthService.getserverCartitems()
            for(var i = 0; i < cart.length; i++){ 
                if (cart[i]['item_code'] === item_code) { 
                    cart.splice(i, 1);
                }
            }
            localStorage.setItem("servercart", JSON.stringify(cart));
        return this.add_item(itemData)
        
    }else{

        let cart = AuthService.getCartitems()

        for(var i = 0; i < cart.length; i++){ 
            if (cart[i]['item_code'] === item_code) { 
                cart.splice(i, 1);
            }
        }
        localStorage.setItem("cart", JSON.stringify(cart));
    }
}

async setAddress(addressName){
    const USR_TOKEN = localStorage.getItem("userToken")
    if(USR_TOKEN){
        let adData = {
            'address_name':addressName
        }
        return axios.post(process.env.REACT_APP_API_URL + encodeURI('api/method/wolly.api.set_cart_addresses'), adData,{
            headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
        })
        .then(res=>{  console.log(res.data) })
    }

}

  async updateAddress(addressData){
      // Args address_line1, address_line2, city, country
      const USR_TOKEN = localStorage.getItem("userToken")
      if(USR_TOKEN){

            axios.post(process.env.REACT_APP_API_URL + encodeURI('api/method/windowworld.api.add_new_address'), addressData,{
                headers : {'Authorization' : USR_TOKEN, 
                'content-type': 'application/json'}
            })
            .then(response=>{
                console.log(response.data)
                let adData = {
                    'address_name':response.data['message'].name
                }
                return axios.post(process.env.REACT_APP_API_URL + encodeURI('api/method/windowworld.api.set_cart_addresses'), adData,{
                    headers : {'Authorization' : USR_TOKEN, 
                    'content-type': 'application/json'}
                })
                .then(res=>{  console.log(res.data) })
            })
      }

  }

  async getCart(){
    
    const USR_TOKEN = localStorage.getItem("userToken")
    if(USR_TOKEN){
        
        return axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.get_cart_quote',{
            headers : {'Authorization' : USR_TOKEN, 
            'content-type': 'application/json'}
        })
        .then(response=>{
            localStorage.removeItem('servercart');
            //console.log(response['data']['message']['doc']['items'])
            let ncart = []
            let items = response['data']['message']['doc']['items']
           
            for (let itm in items) {
                
                 
                let itemData={
                    item_code:items[itm].item_code, 
                    item_name:items[itm].item_name,
                    image: items[itm].image,
                    description:items[itm].description, 
                    qty: items[itm].qty, 
                    rate:items[itm].rate
                }
                ncart.push(itemData)
            } 
           
            // alert("getCart cart")
            //console.log(ncart)
            localStorage.setItem("servercart", JSON.stringify(ncart));
            return response.data['message']
        })
        .catch(err=>{return (err)})
    }
}
    async addQty(item,qty){
        
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
            
            //local
            let cart = AuthService.getserverCartitems()
            if(cart.length > 0){
                for( var i = 0; i < cart.length; i++){ 
                    if (cart[i]['item_code'] === item) { 
                        cart[i]['qty']=qty + 1
                    }
                }
                localStorage.setItem("servercart", JSON.stringify(cart));
            }


            const itemData= { 
                item_code:item,
                qty:qty + 1,
            }
            
            
            return this.add_item(itemData)
        }
        else{
            let cart = AuthService.getCartitems()
            if(cart.length > 0){
                for( var i = 0; i < cart.length; i++){ 
                    if (cart[i]['item_code'] === item) { 
                        cart[i]['qty']=qty + 1
                    }
                }
                localStorage.setItem("cart", JSON.stringify(cart));
                await this.update_cart()
                return cart
            }
        }
    }
    async subQty(item,qty){
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){


            let cart = AuthService.getserverCartitems()
            if(cart.length > 0){
                for( var i = 0; i < cart.length; i++){ 
                    if (cart[i]['item_code'] === item) { 
                        cart[i]['qty']=qty - 1
                    }
                }
                localStorage.setItem("servercart", JSON.stringify(cart));
            }


            const itemData= { 
                item_code:item,
                qty:qty - 1,
            }
            
            return this.add_item(itemData)
        }
        else{
            let cart = AuthService.getCartitems()
            
            if(cart.length > 0){
                for( var i = 0; i < cart.length; i++){ 
                        if (cart[i]['item_code'] === item ) { 
                            cart[i]['qty']=qty - 1
                            if(qty <= 1){
                                cart.splice(i, 1);
                            }
                            //arr.splice(i, 1); 
                        }
                }
                localStorage.setItem("cart", JSON.stringify(cart))
                return cart
            }
        }  
    }

    async addToCart(itemData){
        //alert(itemData.item_code)
        console.log('add to  cart')
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){

            let serverData={
                item_code:itemData.item_code, 
                description:itemData.description, 
                qty: itemData.qty, 
                rate:itemData.rate,
            }
            this.add_item(serverData)
            this.update_cart()
            
            let cart = AuthService.getserverCartitems()
            if (cart){
             
               
                cart.push(itemData)
                localStorage.setItem("servercart", JSON.stringify(cart));
                console.log('server cart')
                console.log(cart)
                //this.setState({disabled:"disabled"})
                
            }
            else{
                let ncart = []

                ncart.push(itemData)
                localStorage.setItem("servercart", JSON.stringify(ncart));
                console.log('server cart')
                console.log(ncart)
                //his.setState({disabled:"disabled"})
               

            }

            
            

        }
        else{
            
            let cart = AuthService.getCartitems()
            if (cart){
             
           
                cart.push(itemData)
                localStorage.setItem("cart", JSON.stringify(cart));
                //this.setState({disabled:"disabled"})
                
            }
            else{
                let ncart = []
      
                ncart.push(itemData)
                localStorage.setItem("cart", JSON.stringify(ncart));
                //his.setState({disabled:"disabled"})
               

            }
            await this.update_cart()
           
        }
    }
 
}

export default new CartService();
