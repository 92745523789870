import React, {Component} from 'react';
import axios from 'axios';
import './Pastorder.css';
class Pastorder extends React.Component {
  render() {
    return <div className="container  mb-3">
 <nav aria-label="breadcrumb">
            <ol class="breadcrumb2 breadcrumb brdcum">
                <li class="breadcrumb"><a href="index.html"> Home&nbsp; </a></li>
                <li class="breadcrumb"> / Past Order</li>
            </ol>
            </nav>
   <div className="row">
   <div className="col-md-4"> <h5>View Your PastOrder</h5></div> 
   <div className="col-md-6"> 
 <ul class="subtotal  subtotal-list subtotal ">
 <h6  className="small-header opacity-header margin">03 Apr 2021</h6>
                    <li class=" float-price-right bottom-line"><img src="assets/images/product-img/product-img-1.jpg" class="product-thumbnails" alt=""/><p> 1x Thai Longan Lych </p> <span class="">₹10</span></li>
                    <h6  className="small-header opacity-header margin">03 Apr 2021</h6>
                    <li class=" float-price-right bottom-line "><img src="assets/images/product-img/product-img-1.jpg" class="product-thumbnails" alt=""/><p> 1x Banganapalli Ma.. </p> <span class="">₹10</span></li>
                    <h6  className="small-header opacity-header margin">03 Apr 2021</h6>
                    <li class=" float-price-right  bottom-line"><img src="assets/images/product-img/product-img-1.jpg" class="product-thumbnails" alt=""/><p> 3x Onion 500gm,1x.. </p> <span class="">₹10</span></li>
                  
                    </ul>
</div>
</div>
</div>
}
}
export default Pastorder;