import React, {Component} from 'react';
import axios from 'axios';
import './Harvestoftheday.css';
import {Helmet} from "react-helmet";
import AuthService from '../Services/auth.service';
import CartService from '../Services/cart.service';
import {  InputGroup, Input, Button, InputGroupAddon } from "reactstrap";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import './Products.css';

class Products extends React.Component {
  constructor(props){
    super(props);
        this.state = {
            data_qty : 1,
            btnTxt:'+ Add',
            showQty: undefined,
            disabled:'',
            cartItems:undefined,
            selectedItem: this.props.id.attributes?this.props.id.attributes[0]['item_code']:undefined,
            selectedQty: this.props.id.attributes?this.props.id.attributes[0]['attribute_value']:undefined,
            selectedRate: this.props.id.attributes?this.props.id.attributes[0]['price']:undefined,

        }
    }
    async componentDidMount(){

     this.checkItem(this.props.id.attributes[0]['item_code'])

      /*let cart = AuthService.getCartitems()
      
      try{
          if(cart.length > 0){
            
              for (let itm in cart) {
                  for (let itmm in this.props.id.attributes) {
                       // console.log(cart[itm])
                       // console.log('-----')
                       // console.log(this.props.id.attributes[itmm])
                       // console.log('+++++')
                       if(cart[itm]['item_code']==this.props.id.attributes[itmm]['item_code']){
                            this.setState({showQty:"yes"})
                            this.setState({selectedItem:cart[itm]['item_code']})
                           // this.setState({disabled:"disabled"})
                       }
                  }
              } 
          }
      }
      catch(e){ }*/
    }
    async checkItem(item_code){
      const USR_TOKEN = localStorage.getItem("userToken")
      if(USR_TOKEN){
        let cart = AuthService.getserverCartitems();
        //console.log(cart)
          try{
              if(cart.length > 0){
                  this.setState({showQty:false})
                  for (let itm in cart) {
                          console.log(cart[itm]['item_code'] + " - " + item_code)
                          if(cart[itm]['item_code']==item_code){
                                this.setState({showQty:true})
                                this.setState({selectedItem:cart[itm]['item_code']})
                                this.setState({data_qty:cart[itm]['qty']})
                              // this.setState({disabled:"disabled"})
                          }
                          
                    
                  } 
              }
          }
          catch(e){  }
        /*await CartService.getCart()
        .then(response=>{ 
           
            this.setState({cartItems:response['items']})
            this.setState({showQty:false})
            for (let itm in this.state.cartItems) {
              if(this.state.cartItems[itm]['item_code']==item_code){
                    this.setState({showQty:true})
                    this.setState({selectedItem:this.state.cartItems[itm]['item_code']})
                  // this.setState({disabled:"disabled"})
              }        
            } 
          
        })
        .catch(err=>{ console.log(err)})*/


      }
      else{
          let cart = AuthService.getCartitems()
          try{
              if(cart.length > 0){
                  this.setState({showQty:false})
                  for (let itm in cart) {
                         // console.log(cart[itm]['item_code'] + " - " + item_code)
                          if(cart[itm]['item_code']==item_code){
                                this.setState({showQty:true})
                                this.setState({selectedItem:cart[itm]['item_code']})
                                this.setState({data_qty:cart[itm]['qty']})
                              // this.setState({disabled:"disabled"})
                          }
                          
                    
                  } 
              }
          }
          catch(e){  }
      }
      

    }
    componentDidUpdate(prevState){
     
    }
    async selectItem(e, item, desc, rate){
      e.preventDefault();
      this.ItemCode = e.target.value
      this.setState({ selectedItem: item })
      this.setState({ selectedQty: desc})
      this.setState({ selectedRate: rate })

      this.checkItem(this.ItemCode)
    }
  addQty = async e => {
            
      e.preventDefault();   
      await CartService.addQty(this.state.selectedItem, this.state.data_qty)
      this.setState({ data_qty: this.state.data_qty + 1 })
  }
  subQty= async e => {
      e.preventDefault();
      if(this.state.data_qty > 0){
            await CartService.subQty(this.state.selectedItem, this.state.data_qty )
            if(this.state.data_qty == 1)
            {
                this.setState({ btnTxt:'+ Add'})
                this.setState({ data_qty:1})
                this.setState({showQty:false})
            }
            this.setState({ data_qty: (this.state.data_qty > 0 )?this.state.data_qty - 1 : 1 })
        }     

  }
    addToCart= async e => {
        this.setState({btnTxt:'Adding...'})
        e.preventDefault();
        if(this.state.selectedItem==undefined){
          this.setState({selectedItem:this.props.id.attributes[0]['item_code']})
          this.setState({ selectedQty: this.props.id.attributes[0]['attribute_value']})
          this.setState({ selectedRate: this.props.id.attributes[0]['price']})
        }
              
        let itemData={
          item_code:this.state.selectedItem, 
          item_name:this.props.id.item_name,
          image: this.props.id.image,
          description:this.state.selectedQty, 
          qty: 1, 
          rate:this.state.selectedRate
        }

        await CartService.addToCart(itemData)
        this.setState({showQty:true})
        this.setState({btnTxt:'+ Add'})


        //const delay = ms => new Promise(res => setTimeout(res, ms));
     
    }

  
        render() {
          
          console.log(this.props.id.sale_per)
          { 
            try{
              this.ItemCode = (this.props.id.attributes[0])? this.props.id.attributes[0].item_code : ''
            }
            catch(error){       }
        
        }
          
    return <div className="product"><a className="product-img" href={'/' + this.props.id.route }>
             {(() => {
                          if (this.props.id.sale_per) {
                              return (<span className="sale">{this.props.id.sale_per}% Off </span>)
                            }
                      })()}

            
            <img src={( this.props.id.image )? process.env.REACT_APP_API_URL +this.props.id.image:'/assets/images/missing.jpg'} style={{maxHeight: 150}}/></a>
              <h3 className="product-name">{this.props.id.item_name}{this.props.sale_per}</h3> 
              <div className="row m-0 list-n">
                <div className="col-lg-12 p-0">
                  <div className="product-price">
                  <form className="form-inline">
                      <div className="stepper-widget">
                        <div className="green-select select-bottom-space pdropdown">
                              <div className="select dropbtn">
                              {(() => {
                                         if (this.props.id.sale_per > 0) {
                                              return(<>{this.state.selectedQty} / <del>  Rs  {this.state.selectedRate} </del>  Rs {this.state.selectedRate}</>)
                                         }
                                         else
                                         {
                                            return(<>{this.state.selectedQty} / Rs {this.state.selectedRate}</>)
                                        }

                                        
                              })()}
                              
                                    <img src="/assets/images/darrow.png"  />
                              </div>
                              <ul className="dropdown-content">
                              {
                                    this.props.id.attributes.map((item,j)=>{

                                            if (this.props.id.sale_per > 0) {
                                                    let oldP = item.price / (1 - (this.props.id.sale_per/100) )
                                                      return( <li key={j} value={item.item_code} ><a href="#" onClick={e => this.selectItem(e, item.item_code, item.attribute_value, item.price )} >{item.attribute_value} /<del>Rs {oldP.toFixed(2)} </del> Rs {item.price}</a></li> )

                                                    
                                                }
                                            else{
                                                      return( <li key={j} value={item.item_code} ><a href="#" onClick={e => this.selectItem(e, item.item_code, item.attribute_value, item.price )} >{item.attribute_value} / Rs {item.price}</a></li> )
                                                }

                                      })
                              }
                                 
                              </ul>
                           {/*} <select onChange={this.selectItem} disabled={this.state.disabled}> 
								            {
                             this.props.id.attributes.map((item,j)=>{
                              return( <option key={j} value={item.item_code} data-rate={item.price} data-desc={item.attribute_value} defaultValue={(this.state.selectedItem==item.item_code)?item.item_code:''}>{item.attribute_value} / Rs {item.price}</option> )
                              })
                              }
                            </select>*/}
                          </div>
                        <button style={this.state.showQty ? { display: 'none' } : { display: 'block' }}  className="add2" onClick={this.addToCart}>{this.state.btnTxt} </button>
                        <InputGroup style={this.state.showQty ? {} : { display: 'none'}} className="inpt-height">
													<InputGroupAddon addonType="prepend" onClick={this.subQty}>
														<Button type="button" color="" className="icon-clr" >
															<RemoveOutlinedIcon />
														</Button>
													</InputGroupAddon>
                          <Input type="" className="fnt-12" value={this.state.data_qty} placeholder="number" readOnly />
                          <InputGroupAddon addonType="append" onClick={this.addQty}>
                                  <Button type="button"  color="" className="icon-clr">
                                    <AddOutlinedIcon/>
                                  </Button>
													</InputGroupAddon>
                        </InputGroup>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        
         }
        }
export default Products;