import React, {Component} from 'react';
import axios from 'axios';
import "./Navigation.css";
import { Helmet } from 'react-helmet';
import Signup from '../Pop-up/Signup';
import Signup2 from '../Pop-up/Signup2';
import Register from '../Pop-up/Register';
import OTPCheck from '../Pop-up/OTPCheck';
import Searchbar from '../Components/Searchbar';
import AuthService from '../Services/auth.service';
import CartService from '../Services/cart.service';
import { Redirect, withRouter, useHistory  } from 'react-router-dom';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import DehazeIcon from '@material-ui/icons/Dehaze';
import CloseIcon from '@material-ui/icons/Close';

import { Modal,ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import authService from '../Services/auth.service';
import Referafriend from '../Pop-up/Referafriend';


class Navigation extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cats : [],
            modal:false,
            modal_large: false,
            showSignup:false,
            showOtp:false,
            setPop:false,
            showRegister:false,
            showPopup:true,
            showLogin:false,
            showOTP:false,
            redirect:false,
            loading:'',
            uName:undefined,
            uMobile:undefined,
            uEmail:undefined,
            uNew:false,
            mmenu:'collapse navbar-collapse ',
            toggle:false,
            check:false,
            toggleLeft:false,
            toggleRight:false,
            mmRight:'collapse',
            mmLeft:'collapse',
            loginMsg:'',
            loginMsgClass:'',
            otpMsg:'',
            otpMsgClass:''
        }
        this.tog_large = this.tog_large.bind(this);
        this.hideAll = this.hideAll.bind(this);
        this.handleNoChange = this.handleNoChange.bind(this); 
         this.resendOTP = this.resendOTP.bind(this); 
    }
    showLeftMenu = async e => {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        //.preventDefault();
        if (this.state.toggleLeft){
            this.setState({mmLeft:''})
            this.setState({toggleLeft:false})
            this.setState({mmLeft:'collapse  '})
        }
        else{
          this.setState({mmLeft:'collapsed  '})
          this.setState({toggleLeft:true})
      
          //await delay(1000);
          
        }
       
    }
    showRightMenu = async e => {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        //e.preventDefault();
        if (this.state.toggleRight){
            this.setState({mmRight:''})
            this.setState({toggleRight:false})
            this.setState({mmRight:'collapse  '})
        }
        else{
          this.setState({mmRight:'collapsed  '})
          this.setState({toggleRight:true})
      
          //await delay(1000);
          
        }
       
    }
    showMenu = async e => {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        //e.preventDefault();
        if (this.state.toggle){
            this.setState({mmenu:''})
            this.setState({toggle:false})
            this.setState({mmenu:'collapse navbar-collapse '})
        }
        else{
          this.setState({mmenu:'collapsed navbar-collapse '})
          this.setState({toggle:true})

        }
       
    }
    clickLink = async e => {
        //e.preventDefault();
            this.setState({mmenu:'collapse navbar-collapse'})
            this.setState({toggle:false})
      
    }
    hideAll(){
        this.setState({showPopup:false})
        this.setState({showSignup:false})
        this.setState({showOtp:false})
        this.setState({showRegister:false})
        this.setState({showLogin:false})
        this.setState({showOTP:false})
        this.setState({loginMsg:''})
        this.setState({loginMsg:''})
        this.setState({otpMsg:''})
    }
    tog_large() {

        const USR_TOKEN = localStorage.getItem("userToken")
        //alert(USR_TOKEN)
        if(USR_TOKEN){
           // window.location.href = "/my-account.html"
            
        }
        else{
            this.setState(prevState => ({
                modal_large: !prevState.modal_large
            }));
        }
        
      
        
    }
    async componentDidMount(){
        const USR_TOKEN = localStorage.getItem("userToken")
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.item_groups', {
                    headers : {'Authorization' : process.env.REACT_APP_TOKEN, 'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cats:response.data['message']})
                if(USR_TOKEN){
                    this.setState({check:true})  
                }
               // console.log(this.state.cats)
            })
            .catch(err=>{ console.log(err) })
             await CartService.getCart()
            await CartService.update_cart()
           
    }
    handleClose  = () => {
        this.tog_large()
    }

   
    showRegister = () => {
        this.hideAll()
        this.setState({showRegister:true})
        
    }
    showLogin = () => {
        this.hideAll()
        this.setState({showLogin:true})
       
    }
    handleCancel = () => {
        this.tog_large()
        this.hideAll()
        this.setState({showPopup:true})
    }
    handleCancelRegister = () => {
        this.tog_large()
        this.hideAll()
        this.setState({showPopup:true})
    }
    handleOTP = async (otp) => {
       
        const delay = ms => new Promise(res => setTimeout(res, ms));
        if(!otp || isNaN(otp) || otp.toString().length < 4){
            this.setState({otpMsg:'Invalid OTP'})
            this.setState({otpMsgClass:'text-danger'})
        }
        else{
            if(this.state.uNew){
               
                await AuthService.checkOTP(otp, this.state.uMobile, this.state.uName, this.state.uEmail)
                .then(response=>{ 
                    this.setState({otpMsg:response['msg']})
                    this.setState({otpMsgClass:response['class']})
                    if(response['msg']=='OTP Verified'){
                        
                        this.setState({otpMsg:'Registering user...'})
                        this.setState({otpMsgClass:'text-success'})
                        AuthService.register(this.state.uName, this.state.uMobile, this.state.uEmail)
                            .then(res=>{

                                if(res){
                                    this.setState({otpMsg:res['msg']})
                                    this.setState({otpMsgClass:res['class']})
                                }
                                else{
                                    const user = AuthService.getCurrentUser()
                                    if(user){
                                        this.setState({otpMsg:'Welcome...'})
                                        this.setState({otpMsgClass:'text-success'})
                                        window.location.reload(); 
                                        //window.location.href = "/index.html"
                                    }
                                }
                                
                            })
                            .catch(er=>{ alert(er)})
                    }
                })
                .catch(err=>{ console.log(err)})
                   
            }
            else{
                await AuthService.checkOTP(otp, this.state.uMobile)
                .then(response=>{ 
                    this.setState({otpMsg:response['msg']})
                    this.setState({otpMsgClass:response['class']})
                    //this.hideAll()
                    //this.setState({showOTP:true})
                })
                .catch(err=>{ console.log(err)})
                   
            }
        }
           // this.setState({showSignup:true})
           /// if (res==='OTP Send'){
           

            const user = await AuthService.getCurrentUser()
            if(user){
                await delay(1000);
                this.setState({otpMsg:'Transfer Cart items....'})
                this.setState({otpMsgClass:'text-success'})
                await CartService.transfer_cart()
                await CartService.update_cart().then(resp=>{ console.log(resp) })
                await delay(3000);
                this.tog_large()
                this.setState({loggedin:true})
                this.setState({modal_large:false})
                this.hideAll()
                window.location.reload();
                //window.location.href = "/my-account"
            }
                
                
                
          
                
           // }
           // else
               // alert("Error Sending OTP")
        
            
    }
    handleSignup = async (mobileNo) => {
        if(!mobileNo || isNaN(mobileNo) || mobileNo.toString().length < 10){
            this.setState({loginMsg:'Invalid Mobile No'})
            this.setState({loginMsgClass:'text-danger'})
        }
        else{
           this.setState({uMobile:mobileNo})
           this.setState({loginMsg:'Sending OTP...'})
           this.setState({loginMsgClass:'text-success'})
           await AuthService.sendOTP(mobileNo)
            .then(response=>{ 
                    this.setState({loginMsg:response})
                    this.setState({loginMsgClass:'text-success'})
                    this.hideAll()
                    this.setState({showOTP:true})
            })
            .catch(err=>{console.log(err)})
        }
    }
    
    handleRegister = async (name,mobileNo,email) => {
        
        if(!mobileNo || !name || ! email){
            alert('Please fill all info')
        }
        else{

            this.setState({uMobile:mobileNo})
            this.setState({uName:name})
            this.setState({uEmail:email})
            this.setState({uNew:true})
            var res = await AuthService.sendOTP(mobileNo)
            this.hideAll()
            this.setState({showOTP:true})
            
        }
            
    }
    handleNoChange(e) {
        this.hideAll()
        if(this.state.uNew){
            this.setState({showRegister:true})
        }
        else{
            this.setState({showLogin:true})
        }
        
      }

      resendOTP = async (e) =>{
       
        this.setState({otpMsg:'Sending OTP'})
        this.setState({otpMsgClass:'text-success '})
        
       
         
        await AuthService.sendOTP(this.state.uMobile)
        .then(response=>{ 
                this.setState({otpMsg:'OTP Send'})
                this.setState({otpMsgClass:'text-success '})
              
        })
        .catch(err=>{console.log(err)})
        
      }
    handleLogout(e) {
    
        AuthService.logout();
    
      }

      modalOpen() {
        this.setState({ modal: true });
      }
    render() {
        
        return <div className="sticky-menu sticky-top">
                    <div className="clearfix"></div>
                    <div className="container top-nav d-flex justify-content-between">
                        <a className="toggler-left d-sm-none position-absolute " onClick={this.showLeftMenu} > 
                        <DehazeIcon />
                         </a>
                        <div className={this.state.mmLeft + " left-sidemenu mobile-list"} onClick={this.showLeftMenu} >
                                <CloseIcon className="close" />
                                <div className="container mt-5">
                                        <div>
                                            <ul className="mobile-list pl-1">
                                            {/* <li>All Categories</li>
                                            <li>Fresh Meat</li>
                                            <li>Fresh Fruits</li>
                                            <li>Fresh Vegetables</li>
                                            <li>Gourmet Items</li>
                                            <li>Green & Salads</li>
                                            <li>Herbs</li>
                                            <li>Must Haves</li> */}

                                            <li className="pb-3">Item Categories</li>
                                                    <ul className="mobile-list pl-3">
                                                        
                                                
                                                        <li className="pb-2"><a href="/fresh-fruits">Fresh Fruits</a></li>
                                                        <li className="pb-2"><a onClick={this.clickLink} className="" href="/fresh-vegetables" > Fresh Vegetables </a></li>
                                                        <li className="pb-2"><a href="/gourmet-items">Gourmet Items</a></li>
                                                        <li className="pb-2"><a href="/vegan">Vegan</a></li>
                                                        <li className="pb-2"><a href="/greens-salads">Green & Salads</a></li>
                                                        <li className="pb-2"><a href="/herbs">Herbs</a></li>
                                                        <li className="pb-2"><a href="/must-haves">Must Haves</a></li>
                                                    </ul>
                                                    <hr className="mm-cat-line" />
                                                    <li className="pb-3 ">Quick Links</li>
                                                    <ul className="mobile-list pl-3">       
                                            <li className=" pb-2"><a onClick={this.clickLink} className=" " href="/best-sellers"  > Best Sellers </a></li>
                                            <li className="pb-2"><a onClick={this.clickLink} className=" " href="/best-sellers"  >Best Discounts</a></li>
                                            <li className="pb-2"><a href="/greens-salads">Lettuces</a></li>
                                            <li className="pb-2"><a href="/greens-salads">Fresh Items</a>+</li>
                                            </ul>
                                            </ul>
                                        

                                        </div>
                                </div>
                        </div>
                        <div className="logo-c"><a className="navbar-brand" href="/index.html"> <img src="/assets/images/logo.svg" alt="" title="" className="img-fluid" /> </a>
                        </div>
                        <a className="toggler-right d-sm-none  position-absolute"  onClick={this.showRightMenu} > <AccountCircleOutlinedIcon /> </a>
                            <div className={this.state.mmRight + " right-sidemenu "} onClick={this.showRightMenu} >
                                <CloseIcon className="close" />
                                <div className="container mt-5">
                                        <div className="mobile-list">
                                        {(() => {
                                        if (this.state.check) {
                                            return (
                                                <ul>
                                            
                                            <li><a href="/wallet.html">My Wallet</a></li>
                                            <li><a href="/my-account.html">My Profile</a></li>
                                            <li><a href="/my-orders.html">My Orders</a></li>
                                            <li> <a href="#" onClick={e => this.modalOpen(e)}>Refer</a></li>
                                            <li><a href="#" onClick={e => this.handleLogout(e)}>Log Out</a></li>
                                            {/* <li>Herbs</li>
                                            <li>Must Haves</li> */}
                                            </ul>
                                            )
                                        }  else {
                                        
                                        return (
                                            <ul>
                                            <li><a href="#" onClick={this.tog_large}>Log In / Sign Up</a></li>
                                            
                                            
                                            
                                            </ul>
                                        )
                                        }
                                    })()}
                                        

                                        </div>
                                </div>
                            </div>
                        
                        <Searchbar/>
                        
                        <div className="rate-price nav-1 ">
                            <ul>
                                {/*<li><a href="#"><SearchOutlinedIcon /></a></li>
                                <li><a href="#"><RoomOutliaanedIcon /></a></li>       */}               
                                <li className="dropdown"> <a className="" href="#"  > <AccountCircleOutlinedIcon /></a>
                                {(() => {
                                        if (this.state.check) {
                                            return (
                                                <div className="dropdown-content">
                                                <a href="/wallet.html">My Wallet</a>
                                                <a href="/my-account.html">My Profile</a>
                                                <a href="/my-orders.html">My Orders</a>
                                                <a href="#" onClick={e => this.modalOpen(e)}>Refer</a>
                                                <Modal
                                                        size="lg"
                                                        isOpen={this.state.modal}
                                                        show={this.state.modal}
                                                    >
                                                    <ModalHeader toggle={() => this.setState({ modal: false })}>
                                                    </ModalHeader>
                                                    <ModalBody>
                                                            <Referafriend  modalClose={this.modalClose} handleCancel={this.handleCancel} />

                                                    </ModalBody>
                                                
                                                </Modal>
                                                <a href="#" onClick={e => this.handleLogout(e)}>Log Out</a>
                                            </div>
                                            )
                                        }  else {
                                        
                                        return (
                                            <div className="dropdown-content">
                                                        <a href="#" onClick={this.tog_large}>Log In / Sign Up</a>
                                                    </div>
                                        )
                                        }
                                    })()}

                    

                    
                                </li>
                                <li className=""> <a className="dropdown-toggle link" href="/cart.html"><ShoppingCartOutlinedIcon/><span className="circle-2 " id="pro-count">0</span></a></li>
                            </ul>            
                            <Modal
                                size="md"
                                isOpen={this.state.modal_large}
                                toggle={this.tog_large}
                                onHide={this.state.setPop}
                            >
                            <ModalHeader toggle={() => this.setState({ modal_large: false })}>
                            </ModalHeader>
                                <ModalBody>
                                    { this.state.showPopup ? <Signup updateState={this.showLogin} handleRegister={this.showRegister}  /> : ''}
                                    { this.state.showLogin ? <Signup2 msg={this.state.loginMsg} msgClass={this.state.loginMsgClass} updateState={this.handleSignup} handleClose={this.handleClose} handleCancel={this.handleCancel}  /> : ''}
                                    { this.state.showRegister ? <Register updateState={this.handleRegister} handleClose={this.handleClose} handleCancel={this.handleCancelRegister}  /> : ''}
                                    { this.state.showOTP ? <OTPCheck msg={this.state.otpMsg} msgClass={this.state.otpMsgClass} updateState={this.handleOTP} handleNoChange={this.handleNoChange} resendOTP={this.resendOTP} handleCancel={this.handleCancel}  /> : ''}
                                </ModalBody>
                            </Modal>
                        </div>
                    </div> 
                    <div className="clearfix"></div>
                    <div className="fluid-container"> 
                        <nav className="navbar navbar-expand-lg navbar-dark bg-white pl-0 nav-1  ">                      
                            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" onClick={this.showMenu} data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                                 <span className="navbar-toggler-icon"></span> <span className="navbar-toggler-icon"></span> <span className="navbar-toggler-icon"></span>
                                  </button>
                            <div className="top-dropdown ml-0 pl-0 pr-0 " >
                            <div className="all-cate custom-select2 dropdown-2" >
                                {/* <select>
                                       <option>All Categories</option>
                                        <option>Fresh Meat</option>
                                        <option>Fresh Fruits</option>
                                        <option>Fresh Vegetables</option>
                                        <option>Gourmet Items</option>
                                        <option>Green & Salads</option>
                                        <option>Herbs</option>
                                        <option>Must Haves</option>
                                </select> */}
                               
                                    {/* <button className="dropbtn-2">All Categories</button>
                                    <div className="dropdown-content-2 pt-3">
                                                        <a href="/fresh-fruits">Fresh Fruits</a>
                                                        <a onClick={this.clickLink}  href="/fresh-vegetables" > Fresh Vegetables </a>
                                                       <a href="/gourmet-items">Gourmet Items</a>
                                                        <a href="/vegan">Vegan</a>
                                                       <a href="/greens-salads">Green & Salads</a>
                                                        <a href="/herbs">Herbs</a>
                                                       <a href="/must-haves">Must Haves</a>
                                    </div>  */}



<ul className="dropdwn ">
        <li className=""><a href="#">All Categories</a>
            <ul className="pt-3 fnt-13">
               <li className=""><a href="/fresh-fruits">Fresh Fruits</a></li>
               <li className=""><a onClick={this.clickLink} className="" href="/fresh-vegetables" > Fresh Vegetables </a></li>
               <li className=""><a href="/gourmet-items">Gourmet Items</a></li>
               <li className=""><a href="/vegan">Vegan</a></li>
               <li className=""><a href="/greens-salads">Green & Salads</a></li>
               <li className=""><a href="/herbs">Herbs</a></li>
               <li className=""><a href="/must-haves">Must Haves</a></li>                                            
            </ul>
        </li>
    </ul>
                            </div>
                            </div>
                            <div className={this.state.mmenu } id="navbarResponsive">
                            <ul className="navbar-nav d-flex w-100 text-center re-menu  ">
                                <li className="nav-item  flex-fill "> <a onClick={this.clickLink} className="nav-link " href="/best-sellers" id="Dropdown1" > Best Sellers </a>
                                </li>
                                <li className="nav-item flex-fill"> <a  onClick={this.clickLink} className="nav-link dropdown-toggle" href="/fresh-vegetables" > Fresh Vegetables </a>
                                
                                </li>
                                <li className="nav-item flex-fill"> <a onClick={this.clickLink} className="nav-link dropdown-toggle" href="/fresh-fruits"> Fresh Fruits </a>                                </li>
                                <li className="nav-item flex-fill"> <a onClick={this.clickLink} className="nav-link dropdown-toggle" href="#" >Best Discounts </a>
                                </li>
                                <li className="nav-item flex-fill"> <a onClick={this.clickLink}className="nav-link dropdown-toggle" href="/herbs" >Herbs</a>
                                </li>
                            </ul>
                            
                            </div>
                        <div className="clearfix"></div>
                        </nav>
                    </div> 
                    
                </div>
                
    }
}
export default withRouter(Navigation);