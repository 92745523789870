import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect } from 'react-router-dom';

import axios from 'axios';
import './Myprofile.css';
import AuthService from '../Services/auth.service';
import CartService from '../Services/cart.service';
import MiddleSlider from '../Components/Middleslider';
import Breadcrumb from '../Components/Breadcrumb';
import Demowallet from '../Components/Demowallet';
import Address from '../Pop-up/Address';
import Myprofileaddress from '../Pop-up/Myprofileaddress';
import { Modal,ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CloseIcon from '@material-ui/icons/Close';
/* import DeleteIcon from '@mui/icons-material/Delete'; */
class Myprofile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,modal2:false,
      name: "",
      modalInputName: "",
      profile:[],
      address:[],
      cats : []
    };
  }
  tog_large() {

    const USR_TOKEN = localStorage.getItem("userToken")
    //alert(USR_TOKEN)
    if(USR_TOKEN){
        window.location.href = "/my-account.html"
    }
    else{
        this.setState(prevState => ({
            modal_large: !prevState.modal_large
        }));
    }
    
  
    
}
  async getData(){
    const USR_TOKEN = localStorage.getItem("userToken")
    if(USR_TOKEN){
       //alert(USR_TOKEN)
        const user = await AuthService.getCurrentUser();
       
      axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.myProfile',{
            headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({profile:response.data['message']})
            //this.setState({address1:response.data['message'][0]})
           // console.log(response.data['message'])
        })
        
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.get_shipping_addresses',{
            headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({address:response.data['message']})
            //this.setState({address1:response.data['message'][0]})
           // console.log(response.data['message'])
        })

    }
    else{
        alert("Not Logged in")
    }
    
}
async componentDidMount(){
 this.getData()
  
}


handleDelete = Name => {
 //alert("success")
      const dltData= {
            name:Name
            
          }
        
        const USR_TOKEN = localStorage.getItem("userToken")
          if(USR_TOKEN){
            //alert(USR_TOKEN)
              const user =  AuthService.getCurrentUser();
              this.setState({profile:user})
              axios.post(process.env.REACT_APP_API_URL+'api/method/wolly.api.delete_address',dltData,{
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}  
          })     
        .then( 
            
            res =>{  console.log(res) 
              alert("Deleted")
              window.location.href = "/my-account.html"; 
              
            }
                
        )
        
        .catch(
            
            err =>{ console.log(err)
              alert("unable to delete")
            }
        )
          }
          else{
            alert("Not Logged in")
        }
  
 
}
makeDefault = Value => {
 
 //alert("success")
      const def= {
            name:Value
            
          }
        
        const USR_TOKEN = localStorage.getItem("userToken")
          if(USR_TOKEN){
            //alert(USR_TOKEN)
              const user =  AuthService.getCurrentUser();
              this.setState({profile:user})
              axios.post(process.env.REACT_APP_API_URL+'api/method/wolly.api.set_default_address',def,{
                headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}  
          })     
        .then( 
            
            res =>{  console.log(res) 
              alert("changed")
              window.location.href = "/my-account.html"; 
              
            }
                
        )
        
        .catch(
            
            err =>{ console.log(err)
              alert("unable to make default")
            }
        )
          }
          else{
            alert("Not Logged in")
        }
  
        //Value.preventDefault();
}
 







  handleChange(e) {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value
    });
  }
  handleLogout(e) {
    
    AuthService.logout();

  }

  tog_large() {  
    this.setState(prevState => ({
        modal: !prevState.modal
    }));
} 

handleCancel = () => {
this.tog_large()
}

  modalOpen() {
    this.setState({ modal: true });
  }
  handleOpen() {
    this.setState({ modal2: true });
  }

  modalClose= () =>  {
    this.setState(prevState => ({
      modal2: !prevState.modal2
  }));
  }
  render() {
    if(this.state.profile ){
      return <div>
      <div className="container">
        <Breadcrumb first="My Profile"/>
     {/*  <nav aria-label="breadcrumb">
              <ol className="breadcrumb2 breadcrumb brdcum">
                  <li className="breadcrumb"><a href="index.html"> Home&nbsp; </a></li>
                  <li className="breadcrumb"> / My Profile</li>
              </ol>
              </nav> */}
  <div className="container">
          <div className="row">
              <div className="col-md-4">
              <h2>My profile</h2>
             {/*  <p><a href="#" onClick={e => this.handleLogout(e)}>Logout</a></p> */}
              </div>
              <div className="col-md-8 ">
                <div className="bottom-line  line-space">
                <div className="float-right"><p><a href="#" onClick={e => this.handleOpen(e)}><CreateOutlinedIcon  aria-hidden="true" type="button" /></a></p>

                            <Modal
                                size="md"
                                isOpen={this.state.modal2}
                               /*  toggle={this.handleClose} */
                                show={this.state.modal2}
                               /*  onHide={this.state.setPop} */
                            >
                             <ModalHeader toggle={() => this.setState({ modal2: false })}>
                            </ModalHeader>
                            <ModalBody>
                                    <Myprofileaddress   handleCancel={this.modalClose} Fname={this.state.profile.full_name} Email={this.state.profile.email} />

                            </ModalBody>
                          
                            </Modal>



                {/* <Modal show={this.state.modal} handleClose={e => this.modalClose(e)}>
                      <h2>Hello Modal</h2>
                      <div className="form-group">
                        <label>Enter Name:</label>
                        <input
                          type="text"
                          value={this.state.modalInputName}
                          name="modalInputName"
                          onChange={e => this.handleChange(e)}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <button onClick={e => this.handleSubmit(e)} type="button">
                          Save
                        </button>
                      </div>
              </Modal> */}</div>
              <p className="second-line">You can manage and set your address information here</p>
              <div className="float-right inline"><img src="https://png.pngtree.com/png-clipart/20190905/ourmid/pngtree-carrot-cartoon-png-image_1724297.jpg" className="product-thumbnails round-img" alt=""/></div>
      
              <h6 className="opacity-header">Full Name</h6>
        <p>{this.state.profile.full_name}</p>
        <h6 className="opacity-header">Email Address</h6> 
        <p>{this.state.profile.email}</p>
        <h6 className="opacity-email"> Email Address not yet validated</h6>
        <p className="green-link"><a href="#"><u>Resend Validatation Mail</u></a></p>
        <h6 className="opacity-header">Mobile Number</h6> 
        <p className="second-line">{this.state.profile.mobile_no}</p>
        </div>
      
              </div>
          </div>
          <div className="row wallet-balance">
          
                <div className="col-md-4">
                 <h2>Manage Address</h2>
  
                  </div>
              <div className="col-md-8">
              <div className="bottom-line  line-space">
              <p className="second-line">You can manage shipping address from here</p>
              <div className="row  inline ">
              { this.state.address.map((itm,j)=>{
                return(<div key ={j} className=" btn btn-outlines ouline-space border-width mr-2 close-green" >
                  
                      <h6 className="home-header line-space">{itm.address_label}</h6><span className="float-right"><CloseIcon className="my-close" aria-hidden="true" type="button"onClick={(Name) => {if(window.confirm('Delete the Address?')){this.handleDelete(itm.name,j)};}} /* onClick={(Name) => {this.handleDelete(itm.name,j)}} *//></span><br/><br/>
                      <h6 className="name-header ">{itm.full_name}</h6>
                      <p className="address-header">{itm.address_line1}</p><br/>
                      <h6 className="address-header">{itm.address_line2}</h6><br/>
                      <h6 className="address-header">{itm.city}</h6><br/>
                      <h6 className="address-header">{itm.pincode}</h6><br/> 
                      {(() => {
                      if (itm.default=='1') {
                          return (<h6  className="address-header  mt-31">Default</h6>)
                      }  else {
                      
                      return (<a  className="address-header  mt-31" href="#" onClick={(Value) => this.makeDefault(itm.name,j)}>Make Default</a>)
                      }
                })()}
               

                    
                </div>)
                })}

  
                <div className=" btn btn-outlines ouline-space border-width brdcum box-shadow ">
                <p className="green-link max-top-space"><a href="#" type="button" onClick={e => this.modalOpen(e)}><i className="fa fa-plus center-align  " aria-hidden="true"></i></a><br/>
                <a href="#" type="button" onClick={e => this.modalOpen(e)}>Add New Address<br/> Details </a></p>
                <Modal
                                size="md"
                                isOpen={this.state.modal}
                               /*  toggle={this.handleClose} */
                                show={this.state.modal}
                               /*  onHide={this.state.setPop} */
                            >
                             <ModalHeader toggle={() => this.setState({ modal: false })}>
                            </ModalHeader>
                            <ModalBody>
                                    <Address  modalClose={this.modalClose} handleCancel={this.handleCancel} /* DataSubmit={this.dataSubmit()} */ />

                            </ModalBody>
                          
                            </Modal>
                </div>

                    
                  </div>
                  
                   
                    
              </div>
              
              </div> 
        
          </div>
        
          
      </div>
  </div>
  </div>
    }
    else{
      return <Redirect to='/' />
    }
    

}
}

export default Myprofile;

