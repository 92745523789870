import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './Harvestoftheday.css';
import Products from '../Components/Products';
import {Helmet} from "react-helmet";

class Harvestoftheday extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cats : [],       
        }
       /*  this.play = this.play.bind(this);
        this.pause = this.pause.bind(this); */
      }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/method/'+this.props.url+'', {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cats:response.data['message']})
               // console.log("Harvest")
               //console.log(response.data['message'])
            }) 
    }
   /*  play() {
      this.slider.slickPlay();
    }
    pause() {
      this.slider.slickPause();
    } */
    render() {

        var settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 6,
            slidesToScroll: 1,
            pauseOnHover: true,
            nextArrow: (
              <div>
                <div className="next-slick-arrow"> <img src="/assets/images/icon/right.png" width="100%" /> </div>
              </div>
            ),
          prevArrow: (
              <div>
                 <div className="next-slick-arrow"> <img src="/assets/images/icon/Left.png" width="100%" /> </div>
              </div>
            ),
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
              ]
        }
      return <div className="products-section">
      <div className="container">
      <div className="d-flex justify-content-between">
        <h2 className="fadeInDown">{this.props.head}</h2>
        <div className="seeAll" ><a href={this.props.link}><u>See All</u></a></div>
        </div>
            <Slider {...settings}>
          {this.state.cats.map((itm,k)=>{
          return(<Products id= {itm} key={k} />
        
         )
        })}
            </Slider>
   
      </div>
      
    </div>
}}

export default Harvestoftheday;