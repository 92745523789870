import React, {Component} from 'react';
import axios from 'axios';
import './Contactus.css';
import ReactHtml from 'raw-html-react';
import Breadcrumb from '../Components/Breadcrumb';
class Contactus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu : []
            

        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/resource/Web Page/'+this.props.url,{
            headers : {'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({menu:response.data['data']})
            //console.log(response.data['data'])
        })
    }
    render() {
      return <div class="container ">
          <Breadcrumb first={this.props.head}/>
                <h1 class="text-center cont-us mb-3">{this.props.title}</h1>
                <div class="row">
                
                <ReactHtml html={this.state.menu['main_section_html']}/>
                
                
                </div>
    </div>
      
    }
}

export default Contactus;