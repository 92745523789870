import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './Footer.css';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import AuthService from '../Services/auth.service';
import Footer1 from '../Components/Footer1';
import Footer2 from '../Components/Footer2';
import CartService from '../Services/cart.service'; 
class Footer extends React.Component {
    constructor(){
        super();
        this.state = {
            cats : [],
            cartCount:0,
            showBar:false,
            cartTotal:0
        }
    }
    
    async componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.item_groups', {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cats:response.data['message']})
            
            })
            .catch(err=>{console.log(err)})
           // await CartService.update_cart()
        
    }
    render() {

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1
        }
      return <div className="footer">
                <div className="container py-5 ">
                  <Footer1/>
    {/* <div className="col-lg-5 col-md-4 col-sm-4 address wow fadeInLeft">
      <div className="footer-logo"></div>

      <p>Woolly Farms Private Limited,</p>
      <p>2690, 31st Main Rd, behind CPWD quarters, PWD Quarters, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102</p>
      <p>Email: <a href="mailto:mail@woolly.io">mail@woolly.io</a></p>
      <ul className="social-2">
        <li><a href="#" title="facebook"><i className="fa fa-facebook"></i></a></li>
        <li><a href="#" title="instagram +"><i className="fa fa-instagram"></i></a></li>
        <li><a href="#" title="twitter"><i className="fa fa-twitter"></i></a></li>
        <li><a href="#" title="Linkedin"><i className="fa fa-pinterest"></i></a></li>
      </ul>
      <div className="mt-5 text-white"> Copyright © 2021 <a href="#">Woolly Farms Private Limited </a>- All Rights Reserved. </div>

    </div>
   
    <div className="col-lg-3 col-md-4 col-sm-4 footer-link  wow fadeInLeft">
      <h3>My Account</h3>
      <ul>
        <li><a href="my-account.html">My Account</a></li>
        <li><a href="login.html">login</a></li>
        <li><a href="wishlist.html">Wishlist</a></li>
        <li><a href="register.html">Register</a></li>
      </ul>
    </div>
    <div className="col-lg-4 col-md-4 col-sm-4 footer-link  wow fadeInLeft">
      <h3>Other Useful Links</h3>
      <ul>
        <li><a href="cart.html">Contact Us</a></li>
        <li><a href="wishlist.html">Membership</a></li>
        <li><a href="comingsoon.html">Partner with Us</a></li>
        <li><a href="comingsoon.html">Privacy Policy</a></li>
        <li><a href="comingsoon.html">Refund Policy</a></li>
        <li><a href="404.html">Terms of Service</a></li>
      </ul>
    </div> */}


</div>
<footer className="py-4 bg-light">
  <div className="container copy-right">
    <Footer2/>
  </div>
</footer>

                <div className="fixed-bottom footer-cart"> <a className="dropdown-toggle link" href="/cart.html"><ShoppingCartOutlinedIcon/> <span id="fpro-count">{this.state.cartCount}</span> item(s) (Rs  <span id="cart-amount">{this.state.cartTotal}</span>)</a><div className="float-right"><a className="dropdown-toggle link" href="/cart.html"><u>View Cart</u></a></div>
                </div> 
             
</div>
 
}}

export default Footer;