import React, {Component} from 'react';
import axios from 'axios';
import './Optin.css';
import {Helmet} from "react-helmet";
import AuthService from '../Services/auth.service';
import CartService from '../Services/cart.service';
import {  InputGroup, Input, Button, InputGroupAddon } from "reactstrap";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import ReactHtml from 'raw-html-react';
import ReactLoading from 'react-loading';

class Optin extends React.Component {
  constructor(props){
    super(props);
        this.state = {
            rate:'',
            selectedItem: undefined,
            item_name:undefined,
            chkbox:0
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkIFSeleceted = this.checkIFSeleceted.bind(this);
    }
    async componentDidMount(){
      
        this.setState({ selectedItem: this.props.itm.name  })
        this.setState({ item_name: this.props.itm.item_name  })
        this.setState({ rate: this.props.itm.price[0]['price_list_rate'] })
        this.checkIFSeleceted()
    }
    checkIFSeleceted (){
        for (let itm in this.props.cartitems) {
            
            if(this.props.cartitems[itm]['item_code']===this.props.itm.name){
                //alert(this.props.cartitems[itm]['item_code'])
                this.setState({ chkbox: 1  })
            }
                
        }
    }
    componentDidUpdate(prevState){
     
    }
    async handleInputChange(event){
        this.setState({showLoading:<ReactLoading type="bars" color="#000" height={50} width={35} />})
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (target.checked){
            let itemData={
                item_code:this.state.selectedItem, 
                description:this.state.item_name, 
                qty: 1, 
                rate:this.state.rate,
            }
            await CartService.add_item(itemData)
            .then(res=>{ 
                this.props.handleUpdate();
                this.setState({ chkbox: 1  })
                this.setState({showLoading:''})
            })
            
            
        }
            
        else{
            await CartService.subQty(this.state.selectedItem, 1)
            .then(res=>{ 
                this.props.handleUpdate();
                this.setState({ chkbox: 0  })
                this.setState({showLoading:''})
            })

        }
        
            
        
    }
    
    

    render() {
          //console.log(this.props.product)
          
          
    return <div className="row container pb-3 optin-product" >    
                    <div className="col-3 col-md-3 text-center">
                        <img src={process.env.REACT_APP_API_URL + this.props.itm.image}   className="img-fluid" alt=""/>
                    </div>
                    <div className="col-6 col-md-6 bbottom ">
                        <h3 className="media-title font-weight-semibold"> <a href="#" data-abc="true">{this.props.itm.item_name}</a> </h3>
                        {this.state.showLoading}
                        <ReactHtml html={this.props.itm.description}/>
                        <div className="stepper-widget mb-3 product-price">
                        <label>
                        <input className="mt-2"
                            name="optin" type="checkbox"

                            checked={this.state.chkbox}
                            onChange={this.handleInputChange} /> Opt-in
                        </label>
                        </div>
                    </div>   
                    <div className="col-3 col-md-3 text-right bbottom">
                        <h3 className="mb-0 font-weight-semibold">Rs {this.state.rate}</h3>
                        <br/>
                        <br/>
                    </div>
                    
                </div>
        
         }
        }
export default Optin;