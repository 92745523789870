
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { withRouter } from "react-router";
import axios from 'axios';
import ReactHtml from 'raw-html-react' 
import Navigation from '../Components/Navigation'
import './Productdetails.css';
import Similaritems from '../Components/Similaritems'
import Breadcrumb from '../Components/Breadcrumb';
import AuthService from '../Services/auth.service';
import CartService from '../Services/cart.service';
import {  InputGroup, Input,  InputGroupAddon, UncontrolledCollapse, Button, CardBody, Card } from "reactstrap";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
class Productdetails extends React.Component {
  	constructor(props){
        super(props);
        this.state = {
            selectedDesc : [],
            btnTxt:'+ Add',
            cats : [],
            pdt : [],
            rel : [],
            showQty: undefined,
            disabled:'',
            selectedItem: undefined,
            selectedQty: 1,
            selectedRate: undefined,
            selectedLabel: undefined,

           
        }
    }
  async componentDidMount(){
      
      const slug = this.props.match.params.slug;
      const slug1 = this.props.match.params.slug1;
    	const slug2 = this.props.match.params.slug2;
      const route = window.location.pathname
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.get_item_byRoute?route='+ route.slice(1), {
                        headers : {'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cats:response.data.message[0]})
                this.setState({pdt:response.data.message[0]['attributes']})
                 this.setState({rel:response.data.message[0]['related']})
                 
                this.setState({ selectedItem:response.data.message[0]['attributes'][0]['item_code']})
                this.setState({ selectedDesc: response.data.message[0]['attributes'][0]['attribute_value']})
                this.setState({ selectedRate: response.data.message[0]['attributes'][0]['price']})
                
                this.setState({  selectedLabel: response.data.message[0]['attributes'][0]['attribute_value']})

                this.checkItem(response.data.message[0]['attributes'][0]['item_code'])
            })
            .catch(err=>{ console.log(err)})
    }

    async checkItem(item_code){
      const USR_TOKEN = localStorage.getItem("userToken")
      if(USR_TOKEN){
        let cart = AuthService.getserverCartitems();
          try{
              if(cart.length > 0){
                  this.setState({showQty:false})
                  for (let itm in cart) {
                         // alert(cart[itm]['item_code'] + " - " + item_code)
                          if(cart[itm]['item_code']==item_code){
                                this.setState({showQty:true})
                                this.setState({selectedItem:cart[itm]['item_code']})
                                this.setState({selectedQty:cart[itm]['qty']})
                              // this.setState({disabled:"disabled"})
                          }
                          
                    
                  } 
              }
          }
          catch(e){  }
        /*await CartService.getCart()
        .then(response=>{ 
           
            this.setState({cartItems:response['items']})
            this.setState({showQty:false})
            for (let itm in this.state.cartItems) {
              if(this.state.cartItems[itm]['item_code']==item_code){
                    this.setState({showQty:true})
                    this.setState({selectedItem:this.state.cartItems[itm]['item_code']})
                  // this.setState({disabled:"disabled"})
              }        
            } 
          
        })
        .catch(err=>{ console.log(err)})*/


      }
      else{
          let cart = AuthService.getCartitems()
          try{
              if(cart.length > 0){
                  this.setState({showQty:false})
                  for (let itm in cart) {
                          //alert(cart[itm]['item_code'] + " - " + item_code)
                          if(cart[itm]['item_code']==item_code){
                                this.setState({showQty:true})
                                this.setState({selectedItem:cart[itm]['item_code']})
                                this.setState({selectedQty:cart[itm]['qty']})
                              // this.setState({disabled:"disabled"})
                          }
                          
                    
                  } 
              }
          }
          catch(e){  }
      }
      

    }
    addQty = async e => {
            
      e.preventDefault();   
      await CartService.addQty(this.state.selectedItem, this.state.selectedQty)
      this.setState({ selectedQty: this.state.selectedQty + 1 })
  }
  subQty= async e => {
      e.preventDefault();
      if(this.state.selectedQty > 0){
            await CartService.subQty(this.state.selectedItem, this.state.selectedQty )
            if(this.state.selectedQty == 1)
            {
                this.setState({ btnTxt:'+ Add'})
                this.setState({ selectedQty:1})
                this.setState({showQty:false})
            }
            this.setState({ selectedQty: (this.state.selectedQty > 2 )?this.state.selectedQty - 1 : 1 })
            
        }     

  }
  addToCart= async e => {
    this.setState({btnTxt:'Adding...'})
    e.preventDefault();
    if(this.state.selectedItem==undefined){
      this.setState({selectedItem:this.props.id.attributes[0]['item_code']})
      this.setState({ selectedDesc: this.props.id.attributes[0]['attribute_value']})
      this.setState({ selectedRate: this.props.id.attributes[0]['price']})
    }
          

    const delay = ms => new Promise(res => setTimeout(res, ms));
 
    try {
              const user = AuthService.getCurrentUser();
              if(user == null){
                  this.setState({showQty:true})
                  let cart = AuthService.getCartitems()
                  if (cart){
                     let itemData={
                        item_code:this.state.selectedItem, 
                        item_name:this.state.cats['item_name'],
                        image:this.state.cats['image'],
                        description:this.state.selectedDesc, 
                        qty: this.state.selectedQty, 
                        rate:this.state.selectedRate
                      }
                      cart.push(itemData)
                     // console.log(cart)
                      localStorage.setItem("cart", JSON.stringify(cart));
                     // this.setState({disabled:"disabled"})
                      
                  }
                  else{
                      let ncart = []
                     // alert(this.state.selectedRate)
                      let itemData={
                          item_code:this.state.selectedItem, 
                          item_name:this.state.cats['item_name'],
                          image: this.state.cats['image'],
                          description:this.state.selectedDesc, 
                          qty: this.state.selectedQty, 
                          rate:this.state.selectedRate
                      }
                      ncart.push(itemData)
                      localStorage.setItem("cart", JSON.stringify(ncart));
                     // this.setState({disabled:"disabled"})
                  }
                  await CartService.update_cart()
               
            }
            else{
                  
                  let itemData={
                      item_code:this.state.selectedItem, 
                      description:this.state.cats['item_name'], 
                      qty: 1, 
                      rate:this.state.total,
                  }
                  await CartService.add_item(itemData)
                  .then(res=>{
                        CartService.update_cart().then(rr=>{this.setState({showQty:true})})
                        
                  })
                  //await CartService.update_cart()
            }
            this.setState({btnTxt:'+ Add'})

            

              //this.setState({ buttontext:'Product Added'})
              await delay(1000);
              //this.setState({ buttontext:'Add Next Item'})
          
      }
      catch(err) {
          console.log(err)
        //  document.getElementById("options-selected").insertAdjacentHTML("beforeend","<div id='sdrop' ><strong>Drop</strong> : " + e.target.value + "</div>");                                 
      }
}

async selectItem(e, item, desc, rate){
  e.preventDefault();
  this.ItemCode = e.target.value
  this.setState({ selectedItem: item })
  this.setState({ selectedQty: desc})
  this.setState({ selectedRate: rate })

  this.checkItem(this.ItemCode)
}

    render() {
      
         return <div className="product-details">
       <div className="container">
       <Breadcrumb first={this.state.cats['item_group']} second={this.state.cats['item_name']}/>
           </div>
           <div className="container">
            <div className="row col-12">
              <div className="col-md-6"> 
                <div id="">
                  <figure>
                    <img src={process.env.REACT_APP_API_URL + this.state.cats['image']} alt="" style={{ maxWidth: '100%' }}  title={this.state.cats['item_name']} /> 
                  </figure>
                 
                </div>
              </div>
              <div className="col-md-6"> 
                <div className="col-sm-12 col-sm-12 text-right col-12 mb-5">
                  <div className=""> 
                            <StarIcon className="green-star"/> 
                            <StarIcon className="green-star"/> 
                            <StarIcon className="green-star"/> 
                            <StarIcon className="green-star"/> 
                            <StarHalfIcon className="green-star"/> 
                  </div>
                </div>
                <h3 className="product-title mb-0">{this.state.cats['item_name']}</h3>
                <div className="">
                 <div className="desc"> <ReactHtml html={this.state.cats['description']}/></div>
                      <form className="form-inline">
												<div className="stepper-widget">
                        <div className="green-select select-bottom-space pdropdown">
                              <div className="select dropbtn">
                                    {this.state.selectedQty} /  Rs {this.state.selectedRate} 
                                    <img src="/assets/images/darrow.png"  />
                              </div>
                              <ul className="dropdown-content">
                              {
                                    this.state.pdt.map((item,j)=>{

                                            if (this.state.pdt.sale_per) {
                                                    let oldP = item.price / (1 - (this.state.pdt.sale_per/100) )
                                                      return( <li key={j} value={item.item_code} ><a href="#" onClick={e => this.selectItem(e, item.item_code, item.attribute_value, item.price )} >{item.attribute_value} /<del>Rs {oldP.toFixed(2)} </del> Rs {item.price}</a></li> )

                                                    
                                                }
                                            else{
                                                      return( <li key={j} value={item.item_code} ><a href="#" onClick={e => this.selectItem(e, item.item_code, item.attribute_value, item.price )} >{item.attribute_value} / Rs {item.price}</a></li> )
                                                }

                                      })
                              }
                                 
                              </ul>
                           {/*} <select onChange={this.selectItem} disabled={this.state.disabled}> 
								            {
                             this.props.id.attributes.map((item,j)=>{
                              return( <option key={j} value={item.item_code} data-rate={item.price} data-desc={item.attribute_value} defaultValue={(this.state.selectedItem==item.item_code)?item.item_code:''}>{item.attribute_value} / Rs {item.price}</option> )
                              })
                              }
                            </select>*/}
                          </div>
													<button style={this.state.showQty ? { display: 'none' } : { display: 'block' }}  className="add2 width-btn" onClick={this.addToCart}>{this.state.btnTxt}  </button>
                       
        
                          <InputGroup style={this.state.showQty ? {} : { display: 'none' }}>
                            <InputGroupAddon addonType="prepend" onClick={this.subQty}>
                              <Button type="button" color="" >
                                <RemoveOutlinedIcon />
														</Button>
													</InputGroupAddon>
													
                          <Input type="text" className="" value={this.state.selectedQty} placeholder="number" readOnly />
													
                          <InputGroupAddon addonType="append" onClick={this.addQty}>
                              <Button type="button"  color="" >
                                <AddOutlinedIcon/>
                              </Button>
													</InputGroupAddon>
												
                        </InputGroup>
												</div>
											</form>
                      <br/>
                      
                </div>
              </div>
            </div>
            </div>
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                  <ul className="category-list inline">
                  <li className="category-item">
                    <div className="pro-dtls" >
                      <img src="/assets/images/my-product5.jpg" alt="" className="pro-dtls-img " />
                      <p className="new-product">grown using natural farming techniques</p>
                    </div>
                  </li>
                  <li className="category-item">
                    <div className="pro-dtls" >
                      <img src ="/assets/images/my-product6.jpg" className="pro-dtls-img"/>
                      <p className="new-product">freshly delivered in minutes after harvest</p>
                    </div>
                  </li>
                  <li className="category-item">
                    <div className="pro-dtls" >
                      <img src ="/assets/images/my-product1.jpg" className="pro-dtls-img"/>
                      <p className="new-product">enriched with micro nutrients</p>
                    </div>
                  </li>
                  <li className="category-item">
                    <div className="pro-dtls" >
                      <img src ="/assets/images/my-product2.jpg" className="pro-dtls-img"/>
                      <p className="new-product">controlled climate conditions + lighting</p>
                    </div>
                  </li>
                  <li className="category-item">
                    <div className="pro-dtls" >
                      <img src ="/assets/images/my-product4.jpg" className="pro-dtls-img"/>
                      <p className="new-product">super veggies full of flavour</p>
                    </div>
                  </li>
                  <li className="category-item">
                    <div className="pro-dtls" >
                      <img src ="/assets/images/my-product3.jpg" className="pro-dtls-img"/>
                      <p className="new-product">chemical free vegetables</p>
                    </div>
                  </li>
                  </ul>
                  </div>
                </div>
              <div className="container">
            <div className="row">
              <div className="col-12">
               <div className="panel-group" id="accordion">
                  <div className="panel panel-default ">
                    <div className="panel-heading ">
                      <h3 className="panel-title">
                        <a id="description" style={{ marginBottom: '1rem' }} href="#">
                        Description
                        </a>
                      </h3>
                    </div>
                    <UncontrolledCollapse toggler="#description">
                    <Card>
                      <CardBody>
                      <ReactHtml html={this.state.cats['description']}/>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>   
                   

                  </div>
                  <div className="panel panel-default ">
                    <div className="panel-heading">
                      <h3 className="panel-title">
                        <a id="benefits" style={{ marginBottom: '1rem' }}   href="#">
                        Benefits
                        </a>
                      </h3>
                    </div>
                    <UncontrolledCollapse toggler="#benefits">
                    <Card>
                      <CardBody>
                      <ReactHtml html={this.state.cats['benefit']}/>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>   
                  </div>
                  <div className="panel panel-default ">
                    <div className="panel-heading">
                      <h3 className="panel-title">
                        <a id="Overview" style={{ marginBottom: '1rem' }}   href="#">
                        Overview
                        </a>
                      </h3>
                    </div>
                    <UncontrolledCollapse toggler="#Overview">
                    <Card>
                      <CardBody>
                      <ReactHtml html={this.state.cats['overview']}/>
                      </CardBody>
                    </Card>
                  </UncontrolledCollapse>   
                  </div>
                  </div>
                <br/>
                <div className="row">
                  <div className="col-12">
                      <h2 className="wow fadeInDown similar mb-5 ml-0">Similar Items</h2>
                      <Similaritems value={this.state.rel} />

              </div>
            </div>
          </div>
        </div>
      </div> 
      </div>                   
</div>

    }
}
export default withRouter(Productdetails);
