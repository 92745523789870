import React, {Component} from 'react';
import axios from 'axios';
import ReactHtml from 'raw-html-react';
import AuthService from '../Services/auth.service';
class Coupondpdn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu : []
            

        }
    }
    async appyCoupn(e, coupon_code){
        e.preventDefault();
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
            //alert(USR_TOKEN)
            let applied_code = { applied_code: coupon_code}
                axios.post(process.env.REACT_APP_API_URL + 'api/method/wolly.api.apply_coupon',applied_code, {
                    headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
                })
                .then(response=>{
                   console.log(response.data['message'])
                   this.props.handleUpdate();
                })
        }
    }
    async componentDidMount(){  
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
            //alert(USR_TOKEN)
                const user = await AuthService.getCurrentUser();
                this.setState({profile:user})
                axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.get_coupons',{
                    headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
                })
                .then(response=>{
                    this.setState({menu:response.data['message']})
                   // console.log(response.data['message'])
                })
        }

    }
    render() {
        return<div className="container res-address">
        <h3 className="delivery-detials "> Have a coupon code?</h3>
        <div className=" cpn-list">
        <input type="text" className="form-controls-radius copn-inpt-box dropbtn"  placeholder="Enter Code" id="basicpill-firstname-input"/>
          <div className="cpn-list-content">
              
          <span className="Available-Coupons">Available Coupons</span>
          {this.state.menu.map((itm,p)=>{
            return(
            <a href="#" key={p} onClick={(e) => { this.appyCoupn(e, itm.coupon_code);}} ><u>{itm.coupon_code}</u> <br/>
             <span className="Discount-on mt-2"><ReactHtml html={itm.description}/></span>
            </a>
            )
        })}
            
            </div>
          </div>
       {/*  <select className="form-controls-radius" placeholder="Enter Code" id="basicpill-firstname-input"> */}
          
  {/* <option value="Enter Code">Enter Code</option> */}
  {/* <option value="saab">Saab</option>
  <option value="opel">Opel</option>
  <option value="audi">Audi</option> */}
{/* </select> */}
        <button type="button" className="add-to-cart2 btnn-wide copn-inpt-box ">Apply</button>
      </div>
        
}
}

export default Coupondpdn;