import React, {Component} from 'react';
import axios from 'axios';
import './Recent.css';
import ReactHtml from 'raw-html-react' 
import Moment from 'react-moment';
//import ShareIcon from '@material-ui/icons/Share';
import AuthService from '../Services/auth.service';
class Recentissue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        menu : []
        

    }
}
 async componentDidMount(){
    const USR_TOKEN = localStorage.getItem("userToken")
    if(USR_TOKEN){
       //alert(USR_TOKEN)
        const user = await AuthService.getCurrentUser();
        this.setState({profile:user})
        //alert(this.props.id)
axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.get_issue?name='+this.props.id ,{
    headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
})
.then(response=>{
    this.setState({menu:response.data['message']})
    console.log(response.data['message'])
})
}
else{
alert("Not Logged in")
}
} 
  render() {
    return <div>

  <div className="container ">
<h2 className="text-center mb-4">Recent issue</h2>
      <div className="">
         <p className="mb-2 date-issue"><Moment format="DD MMMM yyyy" withTitle>{this.state.menu['opening_date']}</Moment>   </p>
         
         <p className="recnt-iss mb-3"><b>{this.state.menu['subject']}</b></p>
         <p className="status-issue mb-2"><strong>Status:&nbsp;</strong>{this.state.menu['status']}</p>
         <p className="mb-5 des-issu">  <ReactHtml html={this.state.menu['description']}/></p>
          
          
          </div>


  </div>


    </div>
    }
}

export default Recentissue;
