import React, {Component} from 'react';
import axios from 'axios';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Products from './Products';
import ReactLoading from 'react-loading';



class Searchbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu : [],
            loading:'',
        }
    }

    async searchResult(e, keyword){
        //alert(keyword.length)
        if (keyword.length > 2){
            this.setState({loading:<ReactLoading type="bars" color="#000" height={50} width={35} /> })
            axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.item_search?item=' + keyword,{
                headers : {'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({loading:''})
                this.setState({menu:response.data['message']})
                console.log(response.data['message'])
            })
        }
        else{

            this.setState({menu:[]})
            this.setState({loading:''})
        }
        
      }

    render() {

        return  <div className="input-group filter-by pb-2 search_box">
                    <input type="text" className="form-control rounded-lg" name="x"   onChange={e => this.searchResult(e, e.target.value)} placeholder="Search for products" />
                    <span className="input-group-addon">
                        <a href="#"><SearchOutlinedIcon className="fa fa-search icon-position" type="button"/></a>
                    </span>
                    <div className="search_box_content">
                            {this.state.loading}
                            {this.state.menu.map((itm,p)=>{
                                return(<div className={"row row" + p}>
                                            <div className=""><a href={'/' + itm.route }><img src={( itm.image )? process.env.REACT_APP_API_URL +itm.image:'/assets/images/missing.jpg'} style={{maxHeight: 75}}/></a></div>
                                            <div className="text-left  ml-2 my-auto"><a href={'/' + itm.route }><span className="my-auto">{itm.item_name}</span></a></div>
                                            
                                    </div>)
                            })}            
                    </div>
    </div>

    }
}
export default Searchbar;