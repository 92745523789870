import React, {Component} from 'react';

class Emptycart extends React.Component {
  
  render() {
    return <div className="emptycart mt-5 mb-5">
                <h2>OOPS! Your cart is empty</h2>
                <p> Oops! . Looks like your haven't added anything to your cart yet. </p>
                <img src="/assets/images/w298067.jpg" />

                <div class="d-flex justify-content-center">
                        <button type="button" onClick={event =>  window.location.href='/'}  className=" center-block add-to-cart2 btnn-wide " style={{ maxWidth: 250 }} >Shop Now</button>
                </div>

            </div> 
    }
}

export default Emptycart;