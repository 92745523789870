import React, {Component} from 'react';
import Slider from "react-slick";
import axios from 'axios';
import './Categories.css';

class Categories extends React.Component {
    constructor(){
        super();
        this.state = {
            cats : [],animate: false
        }
    
   
     
    }
 
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.item_groups', {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({cats:response.data['message']})
            })
        
    }
    render() {

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
              ]
        }
      return <div>
                    <div className="clearfix"></div>
                        <div id="categories2">
                               <div className="container2">
                                   <div className=" ">
                                       <Slider {...settings}>
                                       {this.state.cats.map((itm,k)=>{
                                        return(  
                                           <a key={k} className="cate-size" href={'/'+itm.route}><div className="cate-size" ><img src={process.env.REACT_APP_API_URL +itm.image} alt="" title="" width="" /><h5 className="cat-head">{itm.name}</h5></div></a>
                                            
                                           )
                                        })}
                                    </Slider>
                                    </div>
                                </div>
                        </div>
                    
            </div>
}}

export default Categories;