import React, {Component} from 'react';
import axios from 'axios';
import './List.css';

import Similaritems from '../Components/Similaritems'
import List from '../Components/List';
import Breadcrumb from '../Components/Breadcrumb';
//import Listproduct from '../Components/Listproduct';


class Productlist extends React.Component {


  constructor(props){
    super(props);
    this.state = {
        cats : [],title:''
    }
}
componentDidMount(){

    axios.get(process.env.REACT_APP_API_URL + 'api'+this.props.url+'', {
                    headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                'content-type': 'application/json'}
        })
        .then(response=>{
            this.setState({cats:response.data['message']})
          console.log(response.data['message'])
          
        })
        this.setState({title:this.props.title})
}




  render() {
    return <div>
<div className="container">
    <Breadcrumb first={this.state.title} second=""/>
	
		
		
			<div className="">							
  	<div className="row">                                    
    	{/*<div className="col-md-2 my-left-menu">
     		<div className="left menu ">
          		<h3>Filters by</h3>
		  		<h3>Sub-Category</h3>
          		<ul className="category">
		  			<li>
					<div className="custom-control custom-checkbox">
                		<input type="checkbox" className="custom-control-input" id="defaultUnchecked-1"/>
                		<label className="custom-control-label" for="defaultUnchecked-1">Sub-Category-one</label>
              		</div>
            	</li>
            	<li>
              	<div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="defaultUnchecked-2"/>
                <label className="custom-control-label" for="defaultUnchecked-2">Sub-Category-two</label>
              </div>
            </li>
            
          </ul>
        <h3>Filter By Type</h3>
        <div className="list-css">
          <ul className="category">
            <li>
             
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="defaultUnchecked-3"/>
                <label className="custom-control-label" for="defaultUnchecked-3">Green</label>
              </div>
            </li>
            <li>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="defaultUnchecked-4"/>
                <label className="custom-control-label" for="defaultUnchecked-4">Brown</label>
              </div>
            </li>
            <li>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="defaultUnchecked-5"/>
                <label className="custom-control-label" for="defaultUnchecked-5">White</label>
              </div>
            </li>
            <li>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="defaultUnchecked-6"/>
                <label className="custom-control-label" for="defaultUnchecked-6">Red</label>
              </div>
            </li>
          </ul>
        </div>


        </div>
      
  </div>*/}
    
    <div className="col-md-12">
          
          <div className="clearfix"></div>
            <div id="products" className="row view-group">
            
           
   <div className="container">
       
          <List  grp={this.state.title} best={this.state.cats} />
       

<div className="container">
<div className=" banner banner1 ">
                          <img src="/assets/images/be.jpg" alt="" className="ban-er"/>
                      </div>
					</div>
			</div>
		</div>
	</div>
	


	

        </div>
        
   
  </div>
  
   
  </div>
  <div className="clearfix"></div>
</div>






}
}

export default Productlist;
