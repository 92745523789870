import React, {Component} from 'react';
import axios from 'axios';
import './Referafriend.css';
import ShareIcon from '@material-ui/icons/Share';
import AuthService from '../Services/auth.service';
class Referafriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        menu : []
        

    }
}
async componentDidMount(){
    const USR_TOKEN = localStorage.getItem("userToken")
    if(USR_TOKEN){
       //alert(USR_TOKEN)
        const user = await AuthService.getCurrentUser();
        this.setState({profile:user})
axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.get_referral_code',{
    headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
})
.then(response=>{
    this.setState({menu:response.data['message']})
    console.log(response.data['message'])
})
}
else{
alert("Not Logged in")
}
}
  render() {
    return <div>

  <div className="container ">

      <div className="text-center p-5">
            <a className="" href=""> <img src="/assets/images/sign2.png" alt="" title="" className="img-fluid mb-1" /> </a>
            <h1 className="refer">Refer a Friend</h1>
            <p class="point">Use your unique code while signing up to get <b class="bld">$50</b> as wallet credit for both you and your friend</p>
            
            <div className="my-box">
              <h1 class="box-no">{this.state.menu}</h1>
          {/* <input type="text-bdr" className=""  placeholder="" id=""/> */}
          </div>
          <div className=" ">
            
              <li class="copy green-link share mt-3"><a href=""><img src="/assets/images/copy.png" alt="" title="" className="img-fluid mr-1"/>Copy Unique Code</a></li>
              <li class="copy green-link share mt-2"><a href=""><ShareIcon className="mr-1"/>Share download link</a></li>
          
          </div>
      </div>

  </div>


    </div>
    }
}

export default Referafriend;
