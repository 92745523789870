import React, {Component} from 'react';
import axios from 'axios';
import './Complaint.css';
import './Complaintitem.css';
class Complaint extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            
          modal_large: false,
          modal: false,
          selectedFile:''
        }
    }

    handleInputChange(event) {
        this.setState({
            selectedFile: event.target.files[0],
          })
    }
    submit(){
        const data = new FormData() 
        data.append('file', this.state.selectedFile)
        axios.post(encodeURI(process.env.REACT_APP_API_URL+'api/method/upload_file'), data, { // receive two parameter endpoint url ,form data 
            headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
            'content-type': 'application/json'}
            })
            .then(res => {
                console.log(res.data['message'])
            })
    }
    tog_large() {

       
        
        
            this.setState(prevState => ({
                modal: !prevState.modal
            }));
        
        
      
        
    }
    handleClose  = () => {
        this.tog_large()
    }
  render() {
    return <div>
    <div className="container ">

<div className="text-center">

    <h3 className="pt-4">Lorem Epsum Mycomplaint pre defined text</h3>

    <div className="big-input mb-2"><input type="text" id="name" class="someclass ins" placeholder="Type in your issue "/></div>
    <div><input type="file" id="actual-btn" hidden/>
        <label for="actual-btn" className=" btn-outline-green add-to-select btn btn-curve w-300-h-45 mb-4 inside-line" onChange={this.handleInputChange}><p className="mt-1">Upload a photo</p></label>
    </div>
 </div>
           {/*  <h6  className="green-label res mb-0 ">Resolution</h6>
             <form className="form-inline  ">
            <div className="border-btm-selection seletion-width mb-5">                                                                  
                <select className=" header-space ref">
                    <option value="">Refund</option>
                    <option value="">Refund1</option>
                    <option value="">Refund2</option>
                    <option value="">Refund3</option>
                </select>
            </div>								

    </form> 
    <div className="inline  ml-2 mb-2 mobil-btn2">
    </form>  */}
    <div className="inline mt-2 ml-3 mb-5 mobil-btn2 com-itm-btn mob-nex-btn mob-nex-btn2">  
        <button type="button" class="  btnn btn-hover btn-outline-success tag-btn mob-can-btn" onClick={e => this.props.handleCancel('false')}>Back</button>

        <button type="button" type="submit" class=" add-to-cart2 btnn tag-btn mb-2" onClick={()=>this.submit()}>Submit</button>
      </div>




</div>

    </div>
        }
    }
    
    export default Complaint;