import React, {Component} from 'react';
import axios from 'axios';
import Slider from "react-slick";

class Categorysub extends React.Component {
	constructor(props) {
		super(props);
	
		this.state = {
		  animate: false
		}
	
		this.handleUpdate = this.handleUpdate.bind(this);
	  }
	handleUpdate = (name) => {

		  this.setState(function() {
			return {
			  selectedopt: name
			}
		  });
        this.props.updateState(name);
	}
	
    render() {

		var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow:8,
			autoplay: true,
            autoplaySpeed: 10000,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
              ]
        }
		let activeClasses = (this.state.animate ? ' active': '');
      return <div className="">
                    <div className="clearfix"></div>
                        <div id="categories2">
                               <div className="container2 mt-3 pt-3">
                                       <Slider {...settings}>
									   {this.props.data.map((itm,k)=>{
										return(
											<a href={"#cat" + k}  ><div key={k} className= {itm.route === this.state.selectedopt ? ' active': ''}><div className="fruit-details hover-border mb-1 mr-2 ml-2"><img src={process.env.REACT_APP_API_URL +itm.image} alt="" className=" center-img  " />
													<p className="new-product">{itm.name}</p>
													</div>
												</div></a>)})}

                                    </Slider>
                                </div>
                        </div>
                    
            </div>

    }
}

export default Categorysub;
