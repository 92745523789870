import React, {Component} from 'react';
import axios from 'axios';
import './Complaintitem.css';
import Complaint from '../Pop-up/Complaint';
import { Modal,ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
class Complaintitem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        cats : [],
        modal_large: false,
        showSignup:false,
        showOtp:false,
        setPop:false,
        showRegister:false,
        showLogin:true,
        showOTP:false,
        redirect:false,
        loading:'',
        uName:undefined,
        uMobile:undefined,
        uEmail:undefined,
        uNew:false,
        mmenu:'collapse navbar-collapse ',
        toggle:false,
        check:false,
        toggleLeft:false,
        toggleRight:false,
        mmRight:'collapse',
        mmLeft:'collapse',
        modal: false,
  name: "",
  modalInputName: "",
  profile:[],
  address:[],
  cats : []
    }
   /*  this.tog_large = this.tog_large.bind(this); */

}
 tog_large() {

    
    
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    
    
  
    
} 
handleClose  = () => {
  this.tog_large()
}

handleSubmit(e) {
    this.setState({ name: this.state.modalInputName });
    this.modalClose();
  }

  modalOpen() {
    this.setState({ modal: true });
  }

  modalClose() {
    this.props.handleCancel('false')
  // this.signUp = this.signUp.bind(this);
}
  render() {
    return <div>

<div className="container">
  <div className="text-left justify-content-center ">

    <div>
      <h3 className="pb-4 pt-4">Lorem Epsum Mycomplaint pre<br/>defined text(complaint text)</h3>
      <h3 className="inline select-items">Select Items </h3> <div className="custom-control custom-checkbox float-right">
                		<input type="checkbox" className="custom-control-input " id="defaultUnchecked"/>
                	 	<label className="custom-control-label  select-all " for="defaultUnchecked"></label>
                  <span className="slect-all">Select All</span>
              		</div>
    </div>

       

                      
      
      
<div >
                    <ul class="category  ">
                    {this.props.head.map((itm,k)=>{
            return(	
                    <li key={k}><div class="custom-control custom-checkbox com-line" ><input type="checkbox" class="custom-control-input" id={'defaultUnchecked-'+k}/><label class="custom-control-label product-details check-label" for={'defaultUnchecked-'+k}><img src={process.env.REACT_APP_API_URL +itm.image} className="product-thumbnails" alt=""/><div className="my-lis float-right ml-2">{itm.item_name} x {itm.qty}</div></label>
                    <span class="float-right rate">₹{itm.net_rate}</span>  </div></li>
 )
})}
                    
         </ul> 
         
 </div>



    <div className="  inline mt-2 ml-3 mb-5 mobil-btn2 com-itm-btn mob-nex-btn mob-nex-btn2">
        <button type="button" class="  btnn btn-hover btn-outline-success tag-btn mob-can-btn  " onClick={() => this.props.handleCancel('false')}>Cancel</button>

        <button type="button" class=" add-to-cart2 btnn tag-btn mb-2  "  onClick={e => this.modalOpen(e)}>Next</button>
        <Modal
                                size="lg"
                                isOpen={this.state.modal}
                               /*  toggle={this.handleClose} */
                                show={this.state.modal}
                               /*  onHide={this.state.setPop} */
                            >
                            
                            <ModalHeader toggle={() => this.props.handleCancel('false')}>
                            </ModalHeader>
                            <ModalBody>
                                    <Complaint  handleCancel={this.handleClose} />

                            </ModalBody>
                          
                            </Modal>
      </div>
</div>


</div>







    </div>
    }
}

export default Complaintitem;
