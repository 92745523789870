import React, {Component} from 'react';
import axios from 'axios';
import './Walletbalance.css';
import { Link } from 'react-router-dom';
import Breadcrumb from '../Components/Breadcrumb';
import AuthService from '../Services/auth.service';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
class Walletbalance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu : []
            
    
        }
    }
    async componentDidMount(){
        const USR_TOKEN = localStorage.getItem("userToken")
        if(USR_TOKEN){
           //alert(USR_TOKEN)
            const user = await AuthService.getCurrentUser();
            this.setState({profile:user})
    axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.wallet_balance',{
        headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
    })
    .then(response=>{
        this.setState({menu:response.data['message']})
        console.log(response.data['message'])
    })
    }
    else{
    alert("Not Logged in")
    }
    }
    render() {
        return <div class="container">
        <Breadcrumb first=" My Wallet Balance"/>
            {/* <nav aria-label="breadcrumb">
            <ol class="breadcrumb2 breadcrumb brdcum">
                <li class="breadcrumb"><a href="index.html"> Home&nbsp; </a></li>
                <li class="breadcrumb"> / My Wallet</li>
            </ol>
            </nav> */}
            <div class="row">
                <div class="col-md-4">
                    <h2><a href="/wallet.html" type="button"><ArrowBackIcon className="mr-2"/></a>Wallet Balance</h2>
                </div>
                <div class=" col-md-5 ">
                        
                        
                        <h3>Add Money to Wallet</h3>
                        <div className="btn btn-outlines ouline-space width-335">
                        <div class="row-form pb-3 pt-3">
                        <input class="form-controls" type="text" placeholder="Enter Amount" id="example-email-input"/>
                        </div>  
                        <div class="tag_bottom">
                            <u class=" ml-1 mr-5">₹100</u>
                            <u class=" ml-0 mr-5">₹500</u>
                            <u class=" ml-0 mr-5">₹1000</u>
                        </div>
                        <button type="button" class=" add-to-cart2 btnn mt-31 my-pay-btn mb-2">Pay Now</button>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <h3> Wallet Options</h3>
                        
                        <p className="green-link my-link mb-5"><a href="/wallet-transfer.html">Show All Transaction History <ArrowForwardIcon/></a></p>
                    
                </div>
                <div class=" col-md-3 wallet-balance "> 
                <span className="price">Current Wallet Balance<p>₹&nbsp;{this.state.menu.balance}</p></span>
                </div>
                </div>
                
    </div>




}
}
export default Walletbalance;