import React, {Component} from 'react';
import axios from 'axios';
class Breadcrumb extends React.Component {
    render() {
        return <nav aria-label="breadcrumb mb-2">
        
            <ol className="breadcrumb2 breadcrumb brdcum">
                <li className="breadcrumb brcr-clr"><a href="index.html "> Home&nbsp; </a></li>
                <li className="breadcrumb brcr-clr"> <a href={'/'+this.props.first}>/&nbsp; {this.props.first} &nbsp;</a>
                </li>
                {(() => {
              if (this.props.second){
                  return (<li className="breadcrumb brcr-clr">/&nbsp; {this.props.second}</li>
                      
                  )
              }
              
              return "";
            })()}



                
            </ol>
        </nav>
            

}
}
export default Breadcrumb;