import React, {Component} from 'react';
import axios from 'axios';
import './Myorders.css';
import { Link } from 'react-router-dom';
import AuthService from '../Services/auth.service';
import Breadcrumb from '../Components/Breadcrumb';
import Moment from 'react-moment';

class Myorders extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                menu : [],rate:[],year: null, month: null, day: null, Mmonth:'',ismonth:false,current:[]
                
        
            };
            this.handleChange = this.handleChange.bind(this);

        }

      componentDidMount(){
        const dateObj = new Date()
        const monthName = dateObj.toLocaleString("default", { month: "2-digit" })
       // this.setState({current:monthName})
        this.getData(monthName)
      }
     getData(Month){
        
   

           //this.setState({mon:monthName})
            const USR_TOKEN = localStorage.getItem("userToken")
           // alert(monthName)
            if(USR_TOKEN){
               //alert(USR_TOKEN)
                const user =  AuthService.getCurrentUser();
                this.setState({profile:user})
               
               
              //alert(this.state.Mmonth)
               // console.log(process.env.REACT_APP_API_URL + 'api/method/wolly.api.my_orders?month='+monthName)
                axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.my_orders?month='+Month,{
                    headers : {'Authorization' : USR_TOKEN, 'content-type': 'application/json'}
                })
                .then(response=>{
                    this.setState({menu:response.data['message']})
                    this.setState({rate:response.data['message']['items']})
                   // console.log(response.data['message'])
                })
            }
            else{
                //alert("Not Logged in")
            }
        }

         
        handleChange(event) 
        {
           //this.setState({Mmonth: event.target.value});
           this.setState({ismonth:true});
           //althisert(.state.Mmonth)
           //  alert(event.target.value)
           this.setState({Mmonth:event.target.value})
           this.getData(event.target.value)
           //event.preventDefault();
         }

  render() {
   //alert(this.state.Mmonth)
    return <div>
        <div className="container">
        <Breadcrumb first="My Orders"/>
       {/*  <nav aria-label="breadcrumb">
            <ol className="breadcrumb2 breadcrumb brdcum">
                <li className="breadcrumb"><a href="index.html"> Home&nbsp; </a></li>
                <li className="breadcrumb"> / My Orders</li>
            </ol>
            </nav> */}
            <div className="container">
           <div className="row">
                <div className="col-md-4">
                    <h2>My Orders</h2>
                </div>
                <div className=" col-md-7 ">
                    <p className="second-line">You can manage your past orders here</p>
                    <h6  className="small-header opacity-header">For the month of</h6>
                    
					
							
								 <form className="form-inline">
									<div className="stepper-widget">
										<div className="">
										    <select className="form-controls header-space "  value={this.state.Mmonth}  onChange={this.handleChange}  >
										    <option value="1"  >January</option>
										    <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
										    <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="" selected>October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
										    </select>
									    </div>
							        </div>
								</form> 

                                {this.state.menu.map((itm,k)=>{
            return(				
					
                    <div  key={k}>
                    <h6 className="opacity-header"><Moment format="DD MMMM yyyy" withTitle>{itm.transaction_date}</Moment></h6>
                    
                        <span className="today-delivery">{itm.status} </span>
                        <div className="mt-2 "> 
                        <span className="rs-right float-right mt-2 amnt-width">₹{itm.total}</span>  
                        <span className="float-right flt-nn mr-1">
                            <Link to={'/orders-details/'+itm.name}>
                                <button type="button" className=" add-to-select btn-curve btn btn-outline-success waves-effect waves-light tag-btn ">View Order Details</button>
                            </Link>
                            <br/>
                            <button type="button" className=" add-to-select btn-curve btn btn-outline-success waves-effect waves-light tag-btn cnl-width">Cancel Order</button>
                            
                        </span>
                   
                    </div>
                        <ul className="subtotal subtotal-list ">
                        {itm.items.map((item,p)=>{
            return(				   
            
            <li className="subtotal " key={p}><img src={process.env.REACT_APP_API_URL +item.image} className="product-thumbnails" alt=""/><p className="itm ">{item.item_name} x {item.qty}</p> </li>
                            )
                    })}
                         
                        </ul>
                        <hr className="mt-5"/>
                    </div>
                     )
                    })}
                    
                   
                  {/*   <div key={k}>
                    <h6 className="opacity-header">01 september 2020</h6>
                    
                    <span className="today-delivery">Delivering Today,  21th at 4:00pm </span> <span className="float-right flt-nn "> <Link to="/orders-details.html"><button type="button" className=" add-to-select btn-curve btn btn-outline-success waves-effect waves-light tag-btn  ">View Order Details</button></Link><span className="ml-1 rs-right">₹40</span></span>
                       
                        
                        <ul className="subtotal subtotal-list ">
                            <li className="subtotal "><img src="assets/images/product-img/product-img-1.jpg" className="product-thumbnails" alt=""/><p className="itm ">Item Name x 1</p> </li>
                            <li className="subtotal"><img src="assets/images/product-img/product-img-1.jpg" className="product-thumbnails" alt=""/><p className="itm">Item Name x 1</p> </li>
                            <li className="subtotal"><img src="assets/images/product-img/product-img-1.jpg" className="product-thumbnails" alt=""/><p className="itm">Item Name x 1</p> </li>
                        </ul>
                        <hr/>
                    </div>
                    <div>
                    <h6 className="opacity-header">01 september 2020</h6>
                        
                        <span className=" Delivered">Delivered</span> <span className="float-right flt-nn"><Link to="/orders-details.html"><button type="button" className=" add-to-select btn-curve btn btn-outline-success waves-effect waves-light  tag-btn ">View Order Details</button></Link><span className="ml-1 rs-right">₹40</span></span>
                       
                        <ul className="subtotal subtotal-list">
                        <li className="subtotal"><img src="assets/images/product-img/product-img-1.jpg" className="product-thumbnails" alt=""/><p className="itm">Item Name x 1</p> </li>
                        <li className="subtotal"><img src="assets/images/product-img/product-img-1.jpg" className="product-thumbnails" alt=""/><p className="itm">Item Name x 1</p> </li>
                        </ul>
                    </div>
                    <hr/>
                    <div>
                    <h6 className="opacity-header">01 september 2020</h6>
                        <span className="Cancelled ">Cancelled</span> <span className="float-right flt-nn"><Link to="/orders-details.html"><button type="button" className=" add-to-select btn-curve btn btn-outline-success waves-effect waves-light  tag-btn ">View Order Details</button></Link><span className="ml-1 rs-right">₹40</span></span>
                        <ul className="subtotal subtotal-list">
                        <li className="subtotal"><img src="assets/images/product-img/product-img-1.jpg" className="product-thumbnails" alt=""/><p className="itm">Item Name x 1</p> </li>
                           <li className="subtotal"><img src="assets/images/product-img/product-img-1.jpg" className="product-thumbnails" alt=""/><p className="itm">Item Name x 1</p> </li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
</div>
</div>
}
}

export default Myorders;
