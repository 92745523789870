import React, {Component} from 'react';
import axios from 'axios';
import AuthService from '../Services/auth.service';
import "./Banner.css";
class Banner extends React.Component {
  constructor(props){
    super(props);
        this.state = {
           banner:[]
        }
    }
    componentDidMount(){
        axios.get(process.env.REACT_APP_API_URL + 'api/method/wolly.api.web_second_slider', {
                        headers : {'Authorization' : process.env.REACT_APP_TOKEN, 
                    'content-type': 'application/json'}
            })
            .then(response=>{
                this.setState({banner:response.data['message'][0]})
            
            })
        
    }
render() {
    return <div className="container">
                <div className="row mb-0">
                    <div className="col-md-12">
                   
                        <a href=""><img className="ban-brdr-rdus" src={process.env.REACT_APP_API_URL +this.state.banner.image}   width="100%" /></a>
                       
                        </div>
                </div>
            </div>
    
}
}

export default Banner;